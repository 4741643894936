.MI862 {
  background: #fff;
  border-radius: 8px;
}
.tsXsq {
  height: 100%;
}
.tsXsq:before {
  width: 100% !important;
  height: 100% !important;
  background-color: #fff !important;
}
._1YtxM {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.v6ccw {
  width: 100%;
}
._2Hmbc {
  margin-bottom: 16px;
}
._2Hmbc:last-child {
  margin-bottom: 0;
}
._1lpm- {
  display: block;
  margin-bottom: 16px;
  width: 100%;
  min-height: 240px;
  resize: vertical;
  border: 1px solid rgba(0,0,0,0.25);
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
}
._1lpm-:hover {
  border: 1px solid rgba(0,0,0,0.45);
}
._1lpm-:focus {
  border: 1px solid #000;
}
._1FtyE {
  box-shadow: 0 0 0 1px #bf3517;
}
._303sA {
  height: 219px;
}
._3HsM- {
  font-size: 16px;
  background-color: #fff !important;
  border: 1px solid rgba(0,0,0,0.25) !important;
}
._3HsM-:hover {
  background-color: #fff !important;
  border: 1px solid rgba(0,0,0,0.45) !important;
}
._2p-hY {
  border-color: #bf3517 !important;
}
._1yGYf {
  color: #000 !important;
}
._2uota {
  fill: rgba(0,0,0,0.8) !important;
  stroke: rgba(0,0,0,0.8) !important;
  stroke-opacity: 1;
}
._2uota:hover {
  fill: rgba(0,0,0,0.8) !important;
  stroke: rgba(0,0,0,0.8) !important;
  stroke-opacity: 1 !important;
}
._1cOuh {
  color: rgba(0,0,0,0.5) !important;
}
.nORS4 {
  display: none;
}
.v3-kA {
  color: #bf3517;
}
._2k3va {
  margin-top: 40px;
  height: 96px;
  width: 100%;
  border-top: 1px solid #f1f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}
._1zNg2 {
  margin-top: 0;
}
._140Uj {
  font-size: 16px;
  height: 48px;
}
._3zEbe {
  display: flex;
  justify-content: space-between;
}
.DL3Xn {
  min-width: auto;
  margin-right: 24px;
}
._2HAHC {
  background-color: #f1f2f2;
  border-radius: 8px;
}
._2R5HT {
  background: #fff;
  border: 1px solid rgba(0,0,0,0.1);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
._2bFBl {
  font-size: 16px;
  color: #000;
}
._2kpDE {
  padding: 16px 24px;
  font-size: 14px;
  line-height: 20px;
  color: #888;
}
._3bxaJ {
  min-width: auto;
  margin-top: 16px;
  height: 20px;
  padding: 0px;
  background-color: inherit;
}
._3bxaJ:not(:last-child) {
  margin-right: 16px;
}
._3bxaJ > a {
  color: #000;
}
._3nYaD {
  padding: 16px 24px;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(0,0,0,0.5);
}
._2wqPn {
  border-bottom: 1px solid #000;
}
