._22LKb {
  color: #000;
  font-size: 16px;
  line-height: 24px;
}
._2jfZd {
  position: absolute;
  width: 20%;
  max-width: 197px;
  height: 187px;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  border-bottom-right-radius: 50px;
  top: 87px;
  right: 107px;
}
@media (max-width: 575px) {
  ._2jfZd {
    display: none;
  }
}
._1XSxK {
  position: absolute;
  top: -1px;
  right: -5px;
  width: 8px;
  height: 8px;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  border-top-right-radius: 2px;
  border-top-left-radius: 1px;
  border-bottom-left-radius: 2px;
  transform: rotate(45deg);
}
