._2rhcC {
  flex: 1;
  display: flex;
  width: 100%;
  padding: 40px 0px;
  flex-direction: column-reverse;
  align-items: center;
  min-height: 412px;
}
._2M-Gr {
  position: relative;
  justify-content: center !important;
  align-items: center !important;
}
@media (min-width: 993px) {
  ._2rhcC {
    justify-content: space-between;
    flex-direction: row;
    min-height: 500px;
  }
}
.a1ssk {
  z-index: 1;
  max-width: 634px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  text-align: center;
}
._1mYZ8 {
  margin-right: 0px;
  text-align: center !important;
  align-items: center;
}
@media (min-width: 993px) {
  .a1ssk {
    text-align: left;
  }
}
._6R_ev {
  font-size: 36px;
  line-height: 46px;
  letter-spacing: -1px;
  font-weight: 300;
  margin-bottom: 24px;
}
@media (min-width: 993px) {
  ._6R_ev {
    font-size: 66px;
    line-height: 76px;
  }
}
._1OT3p {
  max-width: 414px;
  font-size: 16px;
  line-height: 24px;
}
._3pCIJ {
  width: 80%;
  max-width: 416px;
  max-height: 80%;
  margin-bottom: 40px;
  fill: #b6a6eb;
}
.Y9OQl {
  position: absolute;
  z-index: 0;
  fill: #131313;
}
._3bUhU {
  padding-top: 24px;
}
