.FBMax:not(:last-child) {
  margin-bottom: 24px;
}
._3zUaQ {
  margin-top: 24px;
}
._3Pc6a {
  display: none;
  margin-top: 24px;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991px) {
  ._3Pc6a {
    display: flex;
  }
}
