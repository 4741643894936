._2TH1s {
  width: 100%;
  min-height: 48px;
  padding: 12px 50px 12px 16px;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: rgba(0,0,0,0.5);
}
._3Mlix {
  display: flex;
  align-items: center;
  position: relative;
}
._3Mlix:hover ._3oJQ8 {
  visibility: visible;
  opacity: 1;
}
._2TH1s:hover {
  border-color: rgba(0,0,0,0.45);
  color: #000;
}
._2TH1s:focus {
  border-color: #000;
  color: #000;
}
._2TH1s:focus + .YrPAz {
  visibility: visible;
  opacity: 1;
}
._2MOhR {
  padding-left: 56px;
}
._2_X7b::-webkit-input-placeholder {
  color: #c95f80;
}
._2_X7b:-ms-input-placeholder {
  color: #c95f80;
}
._2_X7b::-ms-input-placeholder {
  color: #c95f80;
}
._2_X7b::placeholder {
  color: #c95f80;
}
._1pi-A {
  position: absolute;
  left: 16px;
  width: 24px;
  height: 24px;
}
._3oJQ8 {
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 16px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 8px;
}
._3oJQ8:hover,
._3oJQ8:focus {
  background-color: rgba(0,0,0,0.1);
}
._3oJQ8 > input {
  display: none;
}
._2qswo {
  white-space: pre-wrap;
  visibility: hidden;
  position: absolute;
}
