._3u0kA {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.Dce__ {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
}
._3ER2O {
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}
._2FNI2 {
  margin: 0;
  opacity: 0;
  position: fixed;
  width: 0;
}
.u0FEW {
  padding: 16px 20px;
  height: 56px;
}
._2_rTk {
  cursor: pointer;
}
._2_rTk:first-child {
  margin-bottom: 16px;
}
._2_rTk:first-child:hover {
  color: #c95f80;
}
._2_rTk:first-child input:checked ~ p {
  color: #c95f80;
  border-radius: 8px;
  border: 1px solid #c95f80;
}
._2_rTk:last-child:hover {
  color: #638cf2;
}
._2_rTk:last-child input:checked ~ p {
  color: #638cf2;
  border-radius: 8px;
  border: 1px solid #638cf2;
}
