._3gyVz {
  padding: 8px;
  min-height: 48px;
  max-width: 634px;
  width: 100%;
  display: flex;
  background: #1f1f1f;
  border-radius: 8px;
}
@media (max-width: 575px) {
  ._3gyVz {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
._2RRBS {
  margin-top: 16px;
  margin-bottom: 40px;
  min-height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  ._2RRBS {
    padding: 0 40px;
  }
}
@media (max-width: 575px) {
  ._2RRBS {
    padding: 0 24px;
  }
}
._3lrF4 {
  padding: 8px;
  flex: 1;
  display: flex;
  align-items: center;
  border-radius: 6px;
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._3lrF4:not(._3fSLG):hover {
  cursor: pointer;
  background: rgba(255,255,255,0.1);
}
._3lrF4:not(._3fSLG):hover ._3D0zD {
  border-color: #fff;
}
._3lrF4:not(._3fSLG):hover .BxDmi {
  color: #fff;
}
._1MXxS {
  display: flex;
}
._3D0zD {
  margin-right: 10px;
  min-width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255,255,255,0.75);
  border-radius: 4px;
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.BxDmi {
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(255,255,255,0.5);
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.Pt4SS {
  color: #fff;
}
.oFefE {
  display: none;
}
._1ZkE5 {
  display: none;
}
._1ZkE5:checked + ._3D0zD {
  background-color: #fff;
  border-color: #fff;
}
._1ZkE5:checked + ._3D0zD > .oFefE {
  display: block;
  color: #000;
}
._1ZkE5:disabled + ._3D0zD {
  border-color: rgba(255,255,255,0.25);
}
