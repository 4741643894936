._2LoKY {
  padding: 20px;
  width: 1108px;
  min-height: 56px;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 8px;
  color: #575757;
  transition: all 0.2s ease-in-out;
  background: #131313;
}
@media (max-width: 1238px) {
  ._2LoKY {
    width: 100%;
  }
}
._4Odtj {
  align-items: center;
  background: #131313;
}
._2w9CM {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
._2w9CM:last-child {
  margin-bottom: 0;
}
._3Br_y {
  margin-right: 35px;
  width: 150px;
}
.eUw9U {
  display: flex;
  justify-content: center;
  align-items: center;
}
.eUw9U:not(:last-child) {
  margin-right: 20px;
}
._22Lds {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
}
