.HgqR1 > :not(:last-child) {
  margin-bottom: 24px;
}
._1yLlV {
  display: flex;
  justify-content: space-between;
}
._3NQOR {
  margin-bottom: 24px;
  display: flex;
  align-items: flex-end;
}
._3NQOR > * {
  width: 100%;
}
._3NQOR > *:first-child {
  margin-right: 16px;
}
._3BiPl {
  margin-top: 32px;
}
._36P_B {
  display: none;
}
._1oDE6 {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(0,0,0,0.5);
}
._19Eh1 {
  color: #bf3517;
}
