._3a2Cp {
  position: relative;
  z-index: 1;
}
.uZFv7 {
  display: block;
  max-width: 100%;
  margin: 0 auto;
  border-radius: 20px;
}
._2n_CW {
  border-radius: 0;
}
@media (max-width: 674px) {
  .uZFv7 {
    border-radius: 0;
  }
}
@media (max-width: 567px) {
  .uZFv7 {
    align-self: center;
  }
}
._2d8Vv {
  position: relative;
  cursor: zoom-in;
  transition-duration: 0.175s;
}
.ll1HG {
  position: absolute;
  left: 27px;
  bottom: 21px;
  margin-bottom: 0 !important;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
._3IOiG {
  position: relative;
}
._3othi ._3IOiG {
  width: 236px;
  margin-right: 27px;
}
.aDmA7 {
  position: absolute;
  left: 16px;
  bottom: 16px;
  max-width: calc(100% - 48px);
  padding-right: 16px;
  padding-left: 16px;
  border-color: #fff;
  font-size: 15px;
  color: #474b2f;
}
@media (max-width: 674px) {
  .aDmA7 {
    left: 32px;
  }
}
.aDmA7:before {
  background-color: #fff;
}
@media (hover: hover) {
  .aDmA7:hover {
    border-color: #eceee3;
  }
}
.aDmA7 svg {
  display: none;
}
._3jqO3 {
  position: absolute;
  right: 16px;
  bottom: 20px;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(26,31,25,0.7);
}
._3RUzO {
  display: block;
  opacity: 0.6;
  transition: opacity 0.35s;
}
@media (max-width: 971px) {
  ._3jqO3 {
    display: none;
  }
}
@media (hover: hover) {
  ._3jqO3:hover ._3RUzO {
    opacity: 1;
  }
}
._3iMrX ._3jqO3 {
  display: none;
}
._1QSdN {
  font-size: 18px;
  line-height: 1.444;
}
._1QSdN:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
}
._3othi ._3IOiG {
  margin-bottom: 24px;
  float: left;
}
.DKW4s .uZFv7 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.DKW4s ._2d8Vv {
  position: relative;
  padding-top: 56.25%;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 674px) {
  .DKW4s ._2d8Vv {
    border-radius: 0;
  }
}
._2m461 {
  height: 100%;
  border-radius: 0;
}
._2m461 .uZFv7 {
  height: 100%;
}
._2m461 ._2d8Vv {
  height: 100%;
}
._2m461 ._3IOiG {
  height: 100%;
}
._17OLF {
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 674px) {
  ._17OLF .uZFv7 {
    border-radius: 0;
  }
}
._17OLF ._2d8Vv {
  display: flex;
  justify-content: center;
}
