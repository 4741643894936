._3Rnd2 {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
}
._30S4h {
  margin-bottom: 80px;
  max-width: 852px;
}
@media (max-width: 767px) {
  ._30S4h {
    margin-bottom: 54px;
  }
}
@media (max-width: 575px) {
  ._30S4h {
    margin-top: 24px;
  }
}
._1dKsW {
  color: inherit;
  text-decoration: underline;
}
._13Kx- {
  font-size: 14px;
  line-height: 20px;
}
._3FqKY {
  margin-right: 40px;
}
._3Z302 {
  background: #131313;
  border-radius: 8px;
}
.DLQdf {
  margin-right: 30px;
  margin-bottom: 8px;
  display: flex;
}
._28aP0 {
  display: flex;
}
@media (max-width: 575px) {
  ._28aP0 {
    flex-direction: column;
  }
}
.AEFpm {
  display: flex;
}
@media (max-width: 991px) {
  .AEFpm {
    flex-direction: column;
    flex: 1;
  }
}
._2qzk3 {
  margin-right: 8px;
}
._1lMtc {
  padding: 32px 40px;
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  ._1lMtc {
    padding: 24px 32px;
  }
}
@media (max-width: 575px) {
  ._1lMtc {
    padding: 16px 24px;
    display: block;
  }
}
._3MHke {
  margin-right: 40px;
  flex: 1;
}
@media (max-width: 767px) {
  ._3MHke {
    margin-right: 0;
    margin-bottom: 16px;
  }
}
._2QU_y {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}
._1tGBV {
  color: #facad9;
}
._3gHE9 {
  color: #cfdbf9;
}
._18ifE {
  display: inline-block;
  flex-shrink: 0;
}
._2c5h- {
  background: #facad9;
}
@media (max-width: 575px) {
  ._18ifE {
    width: 100%;
  }
  ._3QcJz {
    width: 100%;
  }
}
._18ifE:not(:last-child) {
  margin-right: 12px;
}
@media (max-width: 575px) {
  ._18ifE:not(:last-child) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 12px;
  }
}
._3im8s {
  margin-top: 60px;
  margin-bottom: 40px;
  text-align: center;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  color: #fff;
}
._1MpxQ {
  color: #cfdbf9;
}
@media (max-width: 767px) {
  ._3im8s {
    margin-top: 60px;
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 32px;
  }
}
._1XMHP {
  margin-bottom: 4px;
  flex-direction: row;
  display: flex;
}
@media (max-width: 767px) {
  ._1XMHP {
    flex-direction: column;
    margin-bottom: 4px;
  }
}
._3BnfH {
  position: relative;
  flex-direction: column;
  padding: 40px;
  flex: 1;
  display: flex;
}
@media (max-width: 991px) {
  ._3BnfH {
    padding: 32px;
  }
}
@media (max-width: 575px) {
  ._3BnfH {
    padding: 24px;
    flex-direction: column;
  }
}
._3BnfH:not(:last-child) {
  margin-right: 4px;
}
@media (max-width: 767px) {
  ._3BnfH:not(:last-child) {
    margin-bottom: 4px;
  }
}
.n4WIM {
  margin: 40px 0px;
  border-color: rgba(255,255,255,0.1);
}
@media (max-width: 575px) {
  .n4WIM {
    margin: 24px 0;
  }
}
._2tYiI {
  padding: 0;
  flex-shrink: 0;
}
@media (max-width: 575px) {
  ._2tYiI {
    width: 100%;
    min-height: auto;
  }
}
@media (max-width: 767px) {
  ._2tYiI {
    min-height: auto;
  }
}
._2wte6 {
  color: #cfdbf9;
}
._3uExR {
  margin-bottom: 16px;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
._1eqMF {
  opacity: 0;
  display: none;
}
.z2FJt {
  color: #70ac5b;
}
.QzNzJ {
  padding: 0;
  border: none;
  display: inline;
  background-color: inherit;
  color: inherit;
  text-decoration: underline;
}
.QzNzJ:hover {
  cursor: pointer;
}
._3YBq5 {
  position: absolute;
  top: -50px;
  right: -50px;
  transform: rotate(15deg);
}
@media (max-width: 767px) {
  ._3YBq5 {
    top: 65px;
  }
}
._3L4Hz {
  padding-bottom: 24px;
  border-bottom: 2px solid #bbdcb0;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}
._1Q6fJ {
  margin-bottom: 8px;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(255,255,255,0.5);
}
._1Q6fJ:first-of-type {
  margin-bottom: 24px;
}
._2qYwt {
  margin-bottom: 25px;
}
._2qYwt > li:not(:last-child) {
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  ._2qYwt > li:not(:last-child) {
    margin-bottom: 9px;
  }
}
@media (max-width: 575px) {
  ._2qYwt > li:not(:last-child) {
    margin-bottom: 20px;
  }
}
._3ud_8 {
  margin-bottom: 24px;
}
._3ud_8 > li:not(:last-child) {
  margin-bottom: 8px;
}
._2WUfg {
  display: flex;
  color: rgba(255,255,255,0.5);
}
._1uEVG {
  color: #fff;
}
._3hjTK {
  margin-right: 11px;
}
.XUtL2 {
  width: 100%;
  height: 48px;
}
._3hGaf {
  margin-bottom: 20px;
}
._2dygW {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  color: rgba(255,255,255,0.5);
}
._21MqU {
  max-width: 480px;
  text-align: center;
}
