._3jXaD {
  position: relative;
  min-width: 320px;
  overflow: hidden;
  background-attachment: fixed;
  background-position: 50% 50%;
  background-size: cover;
}
._1qUlh {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 767px) {
  ._3jXaD {
    padding-bottom: 0;
  }
}
._1oJ_4 {
  background-color: #fff;
}
