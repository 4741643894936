._3dqNr {
  margin: 60px 0;
  padding: 80px 110px;
  max-width: 1070px;
  color: #fff;
  background: #131313;
  border-radius: 8px;
}
@media (max-width: 1365px) {
  ._3dqNr {
    padding: 80px 78px;
  }
}
@media (max-width: 991px) {
  ._3dqNr {
    padding: 60px;
  }
}
@media (max-width: 767px) {
  ._3dqNr {
    margin-top: 0;
    padding: 40px;
  }
}
@media (max-width: 575px) {
  ._3dqNr {
    padding: 40px 24px;
  }
}
._3dqNr a {
  cursor: pointer;
  color: #fff;
  margin: 0px 4px 0px 4px;
}
._3dqNr ol {
  margin-bottom: 20px;
  margin-left: 10px;
  list-style: inside decimal;
  font-size: 14px;
  line-height: 20px;
  color: #808080;
}
._3dqNr > h1 {
  margin-bottom: 60px;
  font-size: 66px;
  line-height: 76px;
  font-weight: 600;
}
@media (max-width: 575px) {
  ._3dqNr > h1 {
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  ._3dqNr > h1 {
    margin-bottom: 40px;
    font-size: 36px;
    line-height: 48px;
  }
}
._3dqNr > h2 {
  font-size: 22px;
  line-height: 32px;
  margin: 60px 0px 20px 0px;
  font-weight: 600;
}
@media (max-width: 767px) {
  ._3dqNr > h2 {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 32px;
  }
}
._3dqNr > p,
._3dqNr div {
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #808080;
}
._3dqNr > p,
._3dqNr div:list-child {
  margin-bottom: 0;
}
