.N6qaU {
  height: 55px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  cursor: text;
}
@media (max-width: 767px) {
  .N6qaU {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: normal;
    justify-content: center;
    align-content: space-between;
  }
}
.N6qaU:hover:not(._3ZAfj) {
  border-color: #cacaca;
}
.N6qaU:hover:not(._3ZAfj) ._2zron {
  color: #808080;
}
._2EAjk,
.N6qaU:hover._2EAjk {
  border-color: #363636;
}
._3ZAfj {
  cursor: default;
}
._2zron {
  min-width: 136px;
  margin-right: 16px;
  color: #cacaca;
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 767px) {
  ._2zron {
    margin-right: 0;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
}
.MYdjG {
  color: #808080;
}
.HzR1T {
  width: 20px;
  height: 20px;
  margin-right: 9px;
}
@media (max-width: 767px) {
  .HzR1T {
    margin-right: 0;
    flex-basis: 100%;
  }
}
._2ltqN {
  width: calc(100% - 24px);
  color: #000;
  font-size: 16px;
  line-height: 24px;
  background-color: transparent;
  border: none;
}
@media (max-width: 767px) {
  ._2ltqN {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}
._1E61f._2ltqN::-webkit-input-placeholder {
  color: #bf3517;
}
._1E61f._2ltqN:-ms-input-placeholder {
  color: #bf3517;
}
._1E61f._2ltqN::-ms-input-placeholder {
  color: #bf3517;
}
._1E61f._2ltqN::placeholder {
  color: #bf3517;
}
._2ltqN::-webkit-input-placeholder {
  color: #cacaca;
}
._2ltqN:-ms-input-placeholder {
  color: #cacaca;
}
._2ltqN::-ms-input-placeholder {
  color: #cacaca;
}
._2ltqN::placeholder {
  color: #cacaca;
}
