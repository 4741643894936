._1_SJy {
  min-height: 64px;
  padding: 12px 16px;
  display: flex;
  background: #131313;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._1_SJy:not(:last-child) {
  margin-bottom: 8px;
}
.RAo__ {
  cursor: pointer;
}
.RAo__:hover {
  background-color: #363636;
}
.oiVu8 {
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fNlwY {
  width: 60px;
  height: 40px;
}
._2aqKT {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.o-l3K {
  font-weight: bold;
}
._2V-M1 {
  color: rgba(255,255,255,0.5);
}
