._1pUvP {
  padding: 8px 16px 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 359px;
  width: 100%;
  height: 32px;
  background-color: rgba(0,0,0,0.04);
  border-radius: 6px;
}
._1pUvP:not(:last-child) {
  margin-bottom: 4px;
}
@media (max-width: 991px) {
  ._1pUvP {
    max-width: none;
  }
}
._1pUvP:hover {
  background-color: rgba(0,0,0,0.08);
}
._2fuvz {
  background-color: rgba(0,0,0,0.04);
}
._2GvSK {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #000;
}
