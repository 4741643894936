._2y5_t {
  max-height: 541px;
  max-width: 852px;
}
._2y5_t > div {
  padding: 34px 40px 24px;
}
@media (max-width: 1365px) {
  ._2y5_t {
    max-width: 756px;
  }
}
@media (max-width: 991px) {
  ._2y5_t {
    max-width: 688px;
  }
}
@media (max-width: 767px) {
  ._2y5_t > div {
    padding: 28px 40px 24px;
  }
}
@media (max-width: 575px) {
  ._2y5_t {
    height: 619px;
    max-height: 100%;
  }
  ._2y5_t > div {
    padding: 24px 16px 16px;
  }
}
._1V9lT {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (max-width: 575px) {
  ._1V9lT {
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
  }
}
.DLcUE {
  height: 36px;
  display: flex;
  flex: 1;
  align-items: center;
  color: #000;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}
@media (max-width: 575px) {
  .DLcUE {
    margin-bottom: 24px;
    width: 100%;
    flex: 0;
    order: 0;
    font-size: 16px;
    text-align: center;
  }
}
._2qZG0 {
  height: 36px;
  line-height: 20px;
  font-weight: normal;
}
@media (max-width: 575px) {
  ._2qZG0 {
    margin-bottom: 75px;
    width: 100%;
    order: 2;
  }
}
@media (max-width: 575px) {
  .j8YNH {
    margin-top: 131px;
    margin-bottom: 0;
    order: 1;
  }
}
._3ZcgO {
  min-height: 350px;
  width: 100%;
  display: flex;
}
@media (max-width: 575px) {
  ._3ZcgO {
    min-height: auto;
  }
}
._34_bn {
  min-height: auto;
  margin-top: 0;
  align-self: center;
}
@media (max-width: 767px) {
  ._34_bn {
    height: calc(100% - 112px);
  }
}
@media (max-width: 575px) {
  ._34_bn {
    height: calc(100% - 112px - 131px);
    order: 2;
  }
}
._3uQV_ {
  width: 100%;
  overflow: auto;
  max-height: 336px;
}
._3uQV_:before {
  background-color: inherit !important;
}
@media (max-width: 575px) {
  ._3uQV_ {
    max-height: 380px;
  }
}
._3uQV_::-webkit-scrollbar {
  width: 10px;
}
._3uQV_::-webkit-scrollbar-track {
  background: none;
  border: 0;
}
._3uQV_::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.45);
  box-shadow: 0 2px 2px rgba(0,0,0,0.12);
  border-radius: 10px;
}
._1JeBO {
  overflow: hidden;
}
._2DllP {
  align-self: flex-end;
  flex-basis: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 575px) {
  ._2DllP {
    margin-top: auto;
    flex-basis: auto;
    order: 3;
    width: 100%;
  }
}
@media (max-width: 575px) {
  .x0ZWv {
    flex-basis: 28%;
  }
}
._121Jz {
  margin-left: 10px;
}
@media (max-width: 575px) {
  ._121Jz {
    margin-left: 8px;
    width: 100%;
  }
}
._27NY6 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 575px) {
  ._27NY6 {
    margin-top: 19px;
    align-items: flex-start;
  }
}
._2RFdV {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
}
._2RFdV:hover {
  cursor: pointer;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0,0,0,0.12);
}
._2Zpbc {
  color: #308d0d;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0,0,0,0.12);
}
._2RFdV:last-child {
  margin-bottom: 2px;
}
._212LW {
  margin-right: 10px;
}
@media (max-width: 767px) {
  ._212LW {
    align-self: flex-start;
  }
}
.__0-j {
  fill: #308d0d;
}
.mQa-r {
  width: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  .mQa-r {
    flex-wrap: wrap;
  }
}
.cbGwA {
  width: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .cbGwA {
    flex-wrap: wrap;
  }
}
@media (max-width: 575px) {
  ._132e2 {
    font-size: 14px;
  }
}
._2zHhY {
  margin-left: 10px;
  color: rgba(0,0,0,0.45);
}
@media (max-width: 767px) {
  ._2zHhY {
    margin-top: 4px;
    margin-left: 0;
    width: 100%;
  }
}
@media (max-width: 575px) {
  ._2zHhY {
    margin-top: 5px;
    margin-left: 0;
  }
}
