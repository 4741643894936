.URxMr {
  display: flex;
  justify-content: center;
}
._2VdCD {
  padding: 0 20px;
  max-width: 1328px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  flex-direction: column;
}
