._3pbFz {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
._3pbFz input {
  display: none;
}
._1W_jk {
  opacity: 0.2;
}
