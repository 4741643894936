._2FTeR {
  padding: 0 40px;
  width: 100%;
  min-height: 64px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  ._2FTeR {
    padding: 16px 40px;
    min-height: 107px;
  }
}
@media (max-width: 575px) {
  ._2FTeR {
    padding-rignt: 24px;
    padding-left: 24px;
  }
}
._3Q9CA {
  border-top: 1px solid rgba(0,0,0,0.05);
  color: rgba(0,0,0,0.25);
}
._3PghO {
  border-top: 1px solid rgba(255,255,255,0.1);
  background-color: #000;
  color: rgba(255,255,255,0.5);
}
.JO2xt {
  margin-bottom: 7px;
}
._3DoQd {
  fill: rgba(0,0,0,0.1);
}
._187jI {
  fill: rgba(255,255,255,0.3);
}
@media (max-width: 767px) {
  .JO2xt {
    width: 100%;
  }
}
._1Swpj {
  margin-left: 30px;
  display: flex;
  flex-wrap: wrap;
  line-height: 16px;
}
@media (max-width: 767px) {
  ._1Swpj {
    margin-top: 16px;
    margin-left: 0;
    width: 100%;
  }
}
._124qM {
  margin-right: 40px;
  color: inherit;
  cursor: pointer;
}
._1eqGx:hover {
  text-decoration: underline;
  color: #000;
}
._3Js8X:hover {
  text-decoration: underline;
  color: #fff;
}
._124qM:last-child {
  margin-right: 0;
}
._1D3Vt {
  margin-left: auto;
}
@media (max-width: 991px) {
  ._1D3Vt {
    width: 117px;
    text-align: right;
  }
}
@media (max-width: 767px) {
  ._1D3Vt {
    margin-top: 12px;
    width: 100%;
    text-align: left;
  }
}
