._3JMOQ {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
._3UqUc:not(:last-child) {
  margin-right: 8px;
}
