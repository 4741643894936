._1HOn1 {
  position: relative;
  min-width: 320px;
  background-attachment: fixed;
  background-position: 50% 50%;
  background-size: cover;
}
._1vXlW {
  min-height: 100vh;
  padding-bottom: 40px;
}
@media (max-width: 674px) {
  ._1HOn1 {
    padding-bottom: 0;
  }
}
.DpqPm {
  background-color: #fff;
}
