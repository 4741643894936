._3oZDs {
  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 0;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  fill-opacity: 0.5;
  transition: all 0.2s ease-in-out;
}
._3oZDs:hover {
  color: #fff;
  fill-opacity: 1;
  background: #000;
}
._3oZDs:active {
  transform: scale(0.9);
}
._2U64q {
  color: #bbdcb0;
  fill-opacity: 1;
  background: #000;
}
._3oZDs > svg {
  width: 22px;
  height: 22px;
}
