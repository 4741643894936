._1BrG5 {
  height: 64px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._1BrG5 * {
  stroke: inherit;
  fill: rgba(0,0,0,0.25);
}
._1BrG5:last-child {
  margin-bottom: 20px;
}
._1BrG5:hover {
  background-color: rgba(207,219,249,0.2);
}
._1BrG5:active,
._1BrG5:focus,
._1-v9v {
  background-color: #cfdbf9;
  outline: none;
}
._25FTE * {
  fill: inherit;
}
._25FTE ._12Pos {
  background-color: #bbdcb0;
}
._25FTE ._1FLGE {
  color: inherit;
}
._25FTE ._336Mc {
  opacity: 1;
}
.VDC3V {
  width: 40px;
  height: 40px;
}
._336Mc {
  position: relative;
  margin-right: 12px;
  opacity: 0.5;
}
._1EcfU {
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
._12Pos {
  background-color: #cacaca;
}
._1FLGE {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0,0,0,0.25);
}
._2OruA {
  margin-left: auto;
  color: rgba(0,0,0,0.25);
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
