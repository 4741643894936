._3Cidj {
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(255,255,255,0.5);
}
._3Lp9f {
  color: #fff;
}
