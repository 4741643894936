._1b3Ys {
  max-width: 600px;
  max-height: 762px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
}
@media (max-height: 762px) {
  ._1b3Ys {
    height: 98%;
  }
}
@media (max-width: 991px) {
  ._1b3Ys {
    max-width: 570px;
  }
}
@media (max-width: 767px) {
  ._1b3Ys {
    max-width: 496px;
  }
}
@media (max-width: 575px) {
  ._1b3Ys {
    margin: 0 40px;
    padding: 0;
  }
}
@media (max-width: 320px) {
  ._1b3Ys {
    margin: 0 8px;
  }
}
.UcPLs {
  padding: 0;
}
.K1eER {
  width: 600px;
}
@media (max-width: 991px) {
  .K1eER {
    width: auto;
  }
}
._1NCgh {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
}
@media (max-width: 767px) {
  ._1NCgh {
    padding: 0 20px;
  }
}
.gUzwr {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0,0,0,0.45);
}
.NEp9- {
  border-bottom: 1px solid #e3e3e3;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
._1-6bk {
  border-top: 1px solid #e3e3e3;
}
._2WBUh {
  display: flex;
  justify-content: center;
  align-items: center;
}
._2WBUh > label {
  display: block;
}
._34AI8 {
  margin-bottom: 32px;
}
._1DFpP {
  padding: 0 40px;
  padding-top: 24px;
  padding-bottom: 50px;
}
@media (max-width: 767px) {
  ._1DFpP {
    padding: 0 20px;
  }
}
@media (max-width: 767px) {
  ._1DFpP {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) and (max-height: 720px) {
  ._1DFpP {
    overflow-y: auto;
    height: calc(100% - 128px);
  }
}
._3NKO7 > ._1hlao:not(:first-child) {
  border-top: none;
}
._3NKO7 > ._1hlao:not(:first-child):hover {
  border-top: 1px solid #cacaca;
  margin-top: -1px;
  height: 56px;
}
._3NKO7 > ._1hlao:not(:first-child).-u2Nx,
._3NKO7 > ._1hlao:not(:first-child):hover._3NKO7 > ._1hlao:not(:first-child).-u2Nx {
  border-top: 1px solid #363636;
  margin-top: -1px;
  height: 56px;
}
._1hlao input:disabled {
  color: #cacaca;
}
._2J94Q > * {
  border-top: none;
}
.-u2Nx + ._1hlao:hover {
  border-top: none !important;
}
._2vlR4 {
  display: none;
}
.oQBw7 {
  margin-top: 32px;
}
._1JvVU {
  align-items: center;
}
._1JvVU > span {
  text-transform: uppercase;
  font-size: 10px;
  line-height: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: rgba(0,0,0,0.45);
}
.sMEV8 {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
._3Nf3L {
  min-width: 136px;
  margin-right: 16px;
}
@media (max-width: 767px) {
  ._3Nf3L {
    display: none;
  }
}
._1JCj8 {
  margin-top: 55px;
}
.MJz_S {
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #cacaca;
}
