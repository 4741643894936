._1UJ5O {
  width: 100% !important;
  min-height: 48px !important;
  display: flex !important;
}
._2YmU_ {
  margin-left: 6px !important;
  color: #fff !important;
}
.tUI_F {
  margin-left: -4px !important;
}
._2WEsK {
  width: 100% !important;
  min-height: 48px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  background: #0c0c0c !important;
  border-radius: 8px !important;
  border: 0 !important;
}
._2WEsK:hover {
  background: #131313 !important;
}
.kJhhi {
  background: #131313 !important;
}
.oQne7 {
  border-color: #bf3517 !important;
}
._3hsLr {
  margin: 0 !important;
  color: #fff !important;
  font-size: 16px !important;
  line-height: 24px !important;
}
._2RLb9 {
  background: #9bb5f8 !important;
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #000 !important;
  background: transparent !important;
  display: flex !important;
  align-items: center !important;
}
._2RLb9:not(:last-child) {
  margin-right: 9px !important;
}
._2RLb9:hover {
  background: transparent !important;
}
.yIBhn {
  margin: 4px 8px 4px 0 !important;
  padding-left: 8px !important;
  height: 32px !important;
  border-radius: 4px !important;
  background: #9bb5f8 !important;
  overflow: hidden !important;
}
._3cfpX {
  width: 24px !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
._3cfpX:hover {
  cursor: pointer !important;
  background: linear-gradient(0deg, rgba(0,0,0,0.1), rgba(0,0,0,0.1)), #9bb5f8 !important;
}
._2YN_q {
  padding: 4px 8px 4px 8px !important;
  flex-wrap: wrap !important;
  color: #fff !important;
}
._24drg {
  padding: 0 16px !important;
}
._1rjEB {
  padding: 0 !important;
}
._3VkMF {
  stroke: #fff;
  stroke-opacity: 0.3;
}
._1YoLz {
  width: 20px;
  height: 20px;
}
._2IU4a {
  margin: 5px !important;
  fill: rgba(255,255,255,0.3) !important;
  border-radius: 2px !important;
  transition: all 0.15s ease-in-out !important;
}
._2IU4a:hover {
  fill: #fff !important;
}
._7N6dJ {
  fill: #fff !important;
  transform: rotate(-180deg) !important;
}
._2P3IX {
  margin-top: 4px !important;
  padding: 0 !important;
  max-height: 180px !important;
  background: #fff !important;
  border: 1px solid #000 !important;
  box-sizing: border-box !important;
  box-shadow: 0 4px 8px rgba(0,0,0,0.16), 0 1px 1px rgba(0,0,0,0.12) !important;
  border-radius: 8px !important;
  overflow: hidden !important;
}
._3Utht {
  max-height: 180px !important;
  overflow: auto !important;
}
._3Utht::-webkit-scrollbar {
  position: relative !important;
  width: 4px !important;
}
._3Utht::-webkit-scrollbar-track {
  background: none !important;
  border: 0 !important;
}
._3Utht::-webkit-scrollbar-thumb {
  height: 120px !important;
  background: rgba(0,0,0,0.1) !important;
  border-radius: 24px !important;
}
._3aXPc {
  color: rgba(0,0,0,0.45);
  padding: 20px 16px;
}
._2Sbfg {
  padding: 12px 16px !important;
  width: 100% !important;
  height: 48px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000 !important;
  background: #fff !important;
  cursor: pointer !important;
}
._2Sbfg:hover {
  background: #cfdbf9 !important;
}
._2Sbfg:active {
  color: #638cf2 !important;
  background: #cfdbf9 !important;
}
._2Sbfg:first-child {
  margin-top: 8px !important;
}
._2Sbfg:last-child {
  margin-bottom: 8px !important;
}
._1waKB {
  margin-left: 8px !important;
  margin-right: 0 !important;
  color: #fff !important;
}
._1u7wL {
  color: rgba(255,255,255,0.3) !important;
}
._2aeif {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  width: 100% !important;
  height: 48px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
