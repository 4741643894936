._34Wvu {
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
}
._1M5_D {
  max-width: 848px;
  width: 100%;
}
@media (max-width: 1365px) {
  ._1M5_D {
    max-width: 756px;
  }
}
@media (max-width: 991px) {
  ._1M5_D {
    max-width: 649px;
  }
}
@media (max-width: 767px) {
  ._1M5_D {
    max-width: 324px;
  }
}
@media (max-width: 575px) {
  ._1M5_D {
    max-width: 270px;
  }
}
._2W3eO {
  margin-top: 40px;
  margin-bottom: 80px;
  width: 100%;
}
._17GMi {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}
._1iqoD {
  max-width: 634px;
  height: 80px;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}
._1iqoD > a {
  color: inherit;
  text-decoration: underline;
}
._3T1BU {
  display: flex;
  flex-direction: column;
}
._1nBjj {
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 767px) {
  ._1nBjj {
    flex-direction: column;
  }
}
._2lNG1 {
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.gBniQ {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0,0,0,0.5);
}
._1AeCg {
  width: 359px;
}
@media (max-width: 991px) {
  ._1AeCg {
    width: 334px;
  }
}
@media (max-width: 767px) {
  ._1AeCg {
    width: 100%;
  }
}
.to4Km {
  padding: 8px 16px 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 359px;
  height: 32px;
  background: rgba(112,172,91,0.2);
  border-radius: 6px;
}
.to4Km:not(:last-child) {
  margin-bottom: 4px;
}
.to4Km:hover {
  background-color: rgba(112,172,91,0.3);
}
._1ln62 {
  background-color: rgba(112,172,91,0.1);
}
@media (max-width: 991px) {
  .to4Km {
    width: 334px;
  }
}
@media (max-width: 767px) {
  .to4Km {
    width: 100%;
  }
}
._2uS5P {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #000;
}
._28tre {
  width: 100%;
  margin-left: 77px;
}
@media (max-width: 1365px) {
  ._28tre {
    margin-left: 30px;
  }
}
@media (max-width: 991px) {
  ._28tre {
    margin-left: 20px;
  }
}
@media (max-width: 767px) {
  ._28tre {
    margin-left: 0;
    margin-top: 52px;
  }
}
