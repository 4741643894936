._3TZ67 {
  padding: 0;
  max-width: 367px;
  width: 100%;
  height: auto;
  box-shadow: 0 14px 26px rgba(0,0,0,0.12);
  border-radius: 8px;
}
@media (max-width: 575px) {
  ._3TZ67 {
    max-width: 320px;
  }
}
._2Dfr0 {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 575px) {
  ._2Dfr0 {
    padding: 40px 16.5px;
  }
}
._2fUg- {
  margin-top: 20px;
  margin-bottom: 38px;
  width: 66px;
  height: 112px;
}
._5YvPL {
  font-size: 22px;
  line-height: 32px;
  font-weight: bold;
  color: #000;
}
._19ZRJ {
  color: rgba(0,0,0,0.5);
  margin-top: 8px;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.nx_5g {
  width: 100%;
  margin-bottom: 30px;
}
._3UHfZ {
  width: 100%;
  display: flex;
  justify-content: center;
}
