._1RyCZ {
  min-height: 58px;
  border-top: 1px solid rgba(255,255,255,0.1);
}
.KdDSL {
  padding-top: 17px;
  min-height: 58px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 17px;
}
._1nt4z {
  flex: 1;
  font-weight: bold;
}
._1IGsl {
  transform: rotate(-90deg);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.MrysI {
  transform: rotate(90deg);
}
._343ri {
  color: rgba(255,255,255,0.75);
  margin-bottom: 17px;
}
._343ri > ul,
._343ri ol {
  list-style: initial;
  padding: revert;
}
