.UTgw- {
  display: flex;
  align-items: center;
}
._1km_W {
  width: 20px;
  height: 20px;
  margin-right: 13px;
}
.PRG4G {
  color: #bf3517;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
