._3IsFz {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 51px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  color: currentColor;
}
._3IsFz:hover:not(.PrQSj) {
  color: #bbdcb0;
}
.PrQSj {
  color: #70ac5b;
}
