._27KON {
  width: 48px;
  height: 48px;
  background: #fff;
}
._27KON:hover {
  background: #e3e3e3;
}
._27KON:focus {
  background: #cfdbf9;
}
._27KON > svg {
  transform: rotate(45deg);
}
