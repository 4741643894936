._3hGeq {
  margin: 24px 0;
}
._1Fd-I {
  display: flex;
  justify-content: space-between;
}
._5i6po {
  margin-bottom: 24px;
  display: flex;
  align-items: flex-end;
}
._5i6po > * {
  width: 100%;
}
._5i6po > *:first-child {
  margin-right: 16px;
}
._2q5US {
  margin-top: 32px;
}
._3Og6R {
  display: none;
}
._13VHb {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(0,0,0,0.5);
}
._1HZ4T {
  color: #bf3517;
}
