.Y3S_b {
  width: 224px;
  padding: 8px;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 0px 0px 24px rgba(0,0,0,0.12);
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  z-index: 1;
}
