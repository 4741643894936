@media (max-width: 971px) {
  ._37QVN {
    margin: 0 !important;
  }
}
@media (max-width: 567px) {
  ._37QVN {
    margin: 0 !important;
  }
}
