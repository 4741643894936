._2MYcu {
  width: 100% !important;
}
._2MYcu:focus {
  outline: none;
}
._1hokM {
  width: 100% !important;
}
._1hokM:focus {
  outline: none;
}
._2MYcu > div {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
  margin-bottom: 40px;
}
._3psVz {
  display: flex;
  width: 100%;
}
.bjxDH {
  width: calc((100% - 16px * 2) / 3);
  margin-right: 16px;
  margin-bottom: 16px;
}
@media (min-width: 993px) {
  .bjxDH:nth-child(3n) {
    margin-right: 0;
  }
}
@media (max-width: 991px) {
  .bjxDH {
    width: calc((100% - 16px) / 2);
  }
  .bjxDH:nth-child(2n) {
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .bjxDH {
    width: 100%;
    margin-right: 0;
  }
}
