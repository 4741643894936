.Jld0d {
  padding: 16px 24px;
  height: 56px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 14px;
  line-height: 24px;
}
@media (max-width: 767px) {
  .Jld0d {
    height: 48px;
  }
}
._3syvi {
  z-index: 4;
  position: fixed;
  right: 40px;
  bottom: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 575px) {
  ._3syvi {
    right: 50%;
    transform: translate(50%);
  }
}
._3rYwg {
  margin-right: 1px;
  padding: 0;
  min-width: 56px;
  width: 56px;
  height: 56px;
  color: transparent;
  border: none;
  border-radius: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
._3RerJ {
  color: #000;
}
@media (max-width: 767px) {
  ._3rYwg {
    height: 48px;
  }
}
._3XUz2 {
  height: 20px;
}
