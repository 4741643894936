.-V1dF {
  position: relative;
  padding: 16px 0 24px;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 8px;
  box-sizing: border-box;
  background: #fff;
  color: #000;
  font-size: 16px;
  line-height: 24px;
}
.QLUEP {
  position: absolute;
  top: 44px;
  right: -182px;
}
._2dsys {
  position: absolute;
  top: 16px;
  left: 32px;
}
._2SzIS {
  padding: 0 32px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.W6odw {
  font-weight: 600;
}
._1NEN8 {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
._1NEN8:not(:first-child) {
  margin-left: 8px;
}
.PlmQs {
  height: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  border: none;
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: #ebf590;
}
.s-697 {
  cursor: pointer;
}
._2KUIe {
  background: #facad9;
}
._1UEMk,
.yhkqG {
  background: #bbdcb0;
}
._1KSS3,
._1OS-0,
._3cGn_,
._3C-Ae,
._3uv-E {
  background: #cfdbf9;
}
._1C21Q {
  background: #dbd6eb;
}
._1bJEA {
  background: #f2dfc2;
}
._1SbQR {
  background: #f7fdc5;
}
._2ZyO6 {
  background: #eff6ed;
}
.PlmQs:hover ._30Hkq {
  visibility: visible;
  opacity: 1;
  width: auto;
  position: static;
}
._30Hkq {
  visibility: hidden;
  opacity: 0;
  width: 0;
  position: absolute;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.EMdCB {
  visibility: visible;
  opacity: 1;
  width: auto;
  position: static;
}
._3YcvB {
  cursor: pointer;
  height: 32px;
  padding: 6px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(0,0,0,0.25);
}
._3YcvB:hover {
  background: rgba(0,0,0,0.05);
}
._2FC0f {
  color: #70ac5b;
}
._3_l_5 {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
._1uwJf {
  display: flex;
  align-items: center;
}
._36rWS {
  margin-top: 24px;
  padding: 0 32px;
}
._36rWS > :not(:last-child) {
  margin-bottom: 4px;
}
._3z3iV {
  color: #000;
}
._1Kb9- {
  padding: 8px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(0,0,0,0.25);
}
.t1FMh {
  margin-right: 15px;
  padding: 0;
  height: auto;
  border: none;
  display: flex;
  align-items: center;
  font-weight: normal;
}
._1iLjP {
  margin-right: 15px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
._1iLjP > svg {
  transform: rotate(45deg);
}
.EaK3D {
  margin-left: 15px;
  color: #000;
  cursor: pointer;
}
.EaK3D > input {
  display: none;
}
.xQ7Bm {
  margin-top: 11px;
  display: flex;
}
._3XhOH {
  display: flex;
  background: #f1f2f2;
  border-radius: 6px;
  padding: 4px;
}
._3XhOH:not(:last-child) {
  margin-right: 4px;
}
.rDH_r {
  margin-right: 4px;
}
.SsRq9 {
  margin-right: 4px;
  font-size: 14px;
  line-height: 24px;
}
._3XPZm {
  font-size: 20px;
  line-height: 24px;
  color: #c95f80;
}
