._2-Qu- {
  width: 100%;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f7fdc5;
}
._1gofM {
  height: 100%;
}
._1X1j5 {
  height: 32px;
}
._3FdIW {
  display: flex;
  justify-content: center;
  align-items: center;
}
._5UbxS {
  top: 72px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  z-index: -1;
  width: calc(100% - 2*20px);
  height: calc(100% - 2*20px - 52px);
  position: absolute;
  align-items: flex-start;
  border-radius: 8px;
  border: 1px dashed #f7fdc5;
  background-color: rgba(19,19,19,0.9);
  box-shadow: 0 0 0 4px #131313;
}
._2greZ {
  z-index: 1;
}
._1z24W {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
}
._2Mr3Q {
  margin: 60px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 50%;
}
._3XNmq {
  margin-bottom: 16px;
  width: 48px;
  height: 48px;
}
._2CW5i {
  padding: 8px 12px;
  height: 36px;
  border-radius: 6px;
  cursor: pointer;
  color: #000;
  text-align: center;
  letter-spacing: 1px;
  line-height: 20px;
  font-size: 12px;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  background-color: #f7fdc5;
}
._2CW5i:active {
  background-color: #ecf598;
  transform: scale(0.9);
}
._2-Qu- ._1VYU9 {
  margin-top: 0;
}
