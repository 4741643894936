.bthB- {
  margin-bottom: 24px;
  padding: 24px;
  max-width: 1070px;
  width: 100%;
  display: flex;
  align-items: center;
  background: #0c0c0c;
  border-radius: 6px;
}
@media (max-width: 575px) {
  .bthB- {
    max-width: 410px;
    flex-direction: column;
  }
}
._1LmMp {
  margin-right: 16px;
  color: rgba(255,255,255,0.5);
}
@media (max-width: 575px) {
  ._1LmMp {
    margin-right: 0;
    margin-bottom: 16px;
  }
}
._2K9W- {
  font-size: 14px;
  line-height: 20px;
  color: rgba(255,255,255,0.75);
}
@media (max-width: 575px) {
  ._2K9W- {
    text-align: center;
  }
}
