._2UjoC {
  color: #000;
}
._2UjoC:before {
  background-color: #fff !important;
}
.nkkjz {
  margin-top: 40px;
}
._2THux {
  padding: 20px;
}
@media (max-width: 575px) {
  ._2THux {
    padding: 20px 0;
  }
}
.wB8ba {
  margin-top: -100px;
}
@media (max-width: 767px) {
  .wB8ba {
    margin-top: -80px;
  }
}
._2ERrc {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 575px) {
  ._2ERrc {
    display: block;
  }
}
._15I1N {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}
._2m_hW {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0,0,0,0.5);
}
.Oset1 {
  display: flex;
  flex-direction: row-reverse;
}
@media (max-width: 575px) {
  .Oset1 {
    margin-left: auto;
  }
}
._2Hmop > a {
  color: #000;
}
._27OeA {
  max-width: 230px;
  height: 32px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex-shrink: 0;
}
@media (max-width: 575px) {
  ._27OeA {
    margin-top: 8px;
    max-width: none;
    justify-content: flex-end;
  }
}
.gtbf_ {
  padding: 4px 8px;
  margin-right: 8px;
  height: 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.xM2Fb {
  background: #e3e3e3;
}
.cYqX3 {
  background: #bbdcb0;
}
.KskzL {
  background: #facad9;
}
._2lD-5 {
  margin-right: 8px;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: rgba(0,0,0,0.5);
  text-transform: uppercase;
}
._39a9U {
  position: relative;
  height: 100px;
  overflow: hidden;
  white-space: break-spaces;
  color: #404040;
}
._9NBdg {
  white-space: break-spaces;
  position: absolute;
  visibility: hidden;
}
._1AJxz {
  position: absolute;
  top: 0;
  margin-top: 40px;
  height: 100%;
  width: 100%;
  display: inline-block;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #fff 100%);
}
._1Yg2Z {
  height: auto;
  min-height: 100px;
}
._1Yg2Z > span {
  display: none;
}
._3cg2r {
  margin-top: 4px;
  padding: 0;
  min-width: auto;
  height: auto;
  line-height: 20px;
}
._39a9U h2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
  margin-bottom: 12px;
  color: #000;
}
._39a9U a {
  text-decoration: underline;
}
._39a9U p {
  font-size: 14px;
  line-height: 20px;
}
._39a9U ul {
  padding: revert;
  list-style: disc;
}
._39a9U ol {
  padding: revert;
  list-style: decimal;
}
._2CM25 {
  display: flex;
}
._3pDp1 {
  width: 100%;
  height: 48px;
  font-size: 16px;
  line-height: 24px;
}
._3pDp1:not(:last-child) {
  margin-right: 20px;
}
._27lPK {
  position: relative;
  margin-bottom: 16px;
  padding-left: 90px;
}
.wGUQQ {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  position: absolute;
  left: 0;
  top: 2px;
}
