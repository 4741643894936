._1EJ_k {
  justify-content: center;
}
._1EJ_k h2 {
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 40px;
}
._1EJ_k p {
  font-size: 16px;
  line-height: 24px;
}
._1EJ_k ul {
  padding: revert;
  list-style: disc;
}
._1EJ_k ol {
  padding: revert;
  list-style: decimal;
}
._2uj8b {
  max-width: 630px;
}
