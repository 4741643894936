._2nUuI {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(0,0,0,0.9);
  overflow-y: auto;
  padding: 40px;
}
._2nUuI > div {
  margin: auto;
}
@media (max-width: 575px) {
  ._2nUuI {
    padding: 20px;
    padding-bottom: 120px;
  }
}
._5S6DX {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  fill: #fff;
  cursor: pointer;
  transition: opacity 150ms ease-in;
  position: absolute;
  top: 24px;
  right: 40px;
  z-index: 1;
}
@media (max-width: 767px) {
  ._5S6DX {
    right: 20px;
  }
}
.f8SQ- {
  padding: 0;
}
._27kxN {
  position: relative;
  z-index: 6;
  max-width: 640px;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
  padding: 40px;
  flex: 1;
}
@media (max-width: 575px) {
  ._27kxN {
    padding: 20px;
  }
}
._3zaaF {
  text-align: center;
  color: rgba(255,255,255,0.5);
  margin: 24px 24px 4px 24px;
}
