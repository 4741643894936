.BugOr {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
._3DUE7 {
  opacity: 0.2;
}
._3CGWu {
  position: absolute;
  z-index: 0;
}
._3DQMb {
  color: #70ac5b;
  fill: #70ac5b;
}
._1qGML {
  opacity: 1;
  color: #f1f2f2;
}
