._1U-3a {
  position: relative;
  width: 100%;
}
._3I7V- {
  position: absolute;
  top: -230px;
  height: 320px;
  width: 100%;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000 53.75%);
}
._1Rw8g {
  position: relative;
  width: 100%;
  height: 320px;
  margin-bottom: 80px;
  padding: 40px 60px;
  display: flex;
  background: #292929;
  border-radius: 8px;
}
@media (max-width: 991px) {
  ._1Rw8g {
    height: auto;
    padding: 32px 40px;
  }
}
@media (max-width: 767px) {
  ._1Rw8g {
    padding: 24px 32px;
  }
}
@media (max-width: 575px) {
  ._1Rw8g {
    padding: 24px;
  }
}
._273lJ {
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  ._273lJ {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 8px;
  }
}
._3vg7P {
  margin-right: 25px;
  font-size: 16px;
  min-width: 150px;
}
@media (max-width: 575px) {
  ._3vg7P {
    margin-right: 0;
  }
}
._1kb_Y {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
@media (max-width: 575px) {
  ._1kb_Y {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 32px;
  }
  ._1kb_Y:last-child {
    margin-bottom: 0px;
  }
}
._4eIPF {
  font-size: 14px;
}
@media (max-width: 575px) {
  ._4eIPF {
    margin-top: 8px;
  }
}
@media (max-width: 575px) {
  ._4eIPF > p {
    display: inline;
  }
}
._2lLT6 {
  position: absolute;
  bottom: -40px;
  right: 50px;
  width: 644px;
  height: 470px;
}
@media (max-width: 1365px) {
  ._2lLT6 {
    width: 541px;
    height: 394px;
    bottom: -34px;
    right: -40px;
  }
}
@media (max-width: 991px) {
  ._2lLT6 {
    display: none;
  }
}
._3haV8 {
  position: absolute;
  bottom: 16px;
  right: 16px;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  ._3haV8 {
    display: none;
  }
}
