._3lCyX {
  width: 100%;
  padding: 20px 32px;
  min-height: 66px;
  border: none;
  border-top: 1px solid rgba(0,0,0,0.1);
  border-bottom: 1px solid rgba(0,0,0,0.1);
  font-size: 20px;
  line-height: 24px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._2KFmI {
  display: flex;
}
._3lCyX:hover {
  border-color: rgba(0,0,0,0.5);
}
._3lCyX:focus {
  border-color: #000;
  border-bottom-width: 2px;
}
._2sBxu {
  border-color: #c95f80;
}
._2sBxu::-webkit-input-placeholder {
  color: #c95f80;
}
._2sBxu:-ms-input-placeholder {
  color: #c95f80;
}
._2sBxu::-ms-input-placeholder {
  color: #c95f80;
}
._2sBxu::placeholder {
  color: #c95f80;
}
._16_X0 {
  white-space: pre-wrap;
  visibility: hidden;
  position: absolute;
}
