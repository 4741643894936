._3Ad9D {
  min-width: 240px;
  width: 100%;
  height: 44px;
  padding: 12px;
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.1);
  box-sizing: border-box;
  border-radius: 4px;
}
._3huo6 {
  flex: 1;
  border: none;
  text-transform: uppercase;
}
._2lk0n {
  padding: 0;
  border: none;
  width: auto;
  min-width: auto;
  height: auto;
  display: flex;
  align-items: center;
}
