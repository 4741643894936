.ogxU2 {
  position: relative;
  z-index: 2;
  margin-top: 20px;
  width: 100%;
}
._1lLnG {
  margin: 16px 0px 16px 0px;
  position: relative;
  z-index: 1;
  color: rgba(255,255,255,0.5);
}
._3dJ99 {
  position: absolute;
  width: calc(100% + 40px);
  height: calc(100% + 20px);
  left: -20px;
  z-index: 1;
  border-radius: 0px 0px 8px 8px;
  background: #1e1e1e;
}
._3VtRL {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
._17ZER {
  flex: 1;
  min-width: 250px;
  padding: 4px;
}
._2XPMf {
  border: 1px solid rgba(0,0,0,0.25) !important;
}
._1mOwS {
  border-color: #bf3517 !important;
}
