._3eict {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
}
._3POlK {
  height: 488px;
  overflow-y: auto;
}
._30RDj {
  margin-top: 24px;
  display: flex;
  align-items: center;
}
._3L8p0 {
  flex: 1;
}
._1IW2g {
  display: flex;
}
._1c1-n:not(:last-child) {
  margin-right: 4px;
}
