._3SgJI {
  display: flex;
  border-radius: 8px;
  color: rgba(255,255,255,0.75);
}
._3hlpH {
  margin-right: 8px;
  width: 22px;
  height: 22px;
}
.-HeeQ {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}
