._2wdzC {
  width: 92px;
}
._3pkji {
  width: 100%;
}
._3pkji > button {
  display: block;
  width: 100%;
}
._3pkji > button:not(:last-child) {
  margin-bottom: 4px;
}
._3u2wY {
  color: #000;
}
