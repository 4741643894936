._3UpHQ {
  margin-bottom: 24px;
  display: flex;
  align-items: flex-end;
}
._3UpHQ > * {
  width: 100%;
}
._3UpHQ > *:first-child {
  margin-right: 16px;
}
._3dl4v {
  margin-top: 24px;
  margin-bottom: 32px;
}
._2DZ7A {
  margin-bottom: 24px;
}
._2D8U2 {
  display: none;
}
._2mO_0 {
  margin-top: 32px;
}
.y657z {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0,0,0,0.45);
  text-decoration: underline;
}
