._2MeqO {
  display: flex;
  flex-wrap: wrap;
}
._4jaPv {
  height: 32px;
  min-width: auto;
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 16px;
  font-weight: normal;
  border-color: rgba(0,0,0,0.1);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._1y3UB {
  margin-right: 4px;
  margin-top: 4px;
}
._1y3UB:last-child {
  margin-right: 0;
}
._4jaPv:not([disabled]):hover {
  color: #000;
  border-color: #000;
}
._24n20:not([disabled]):hover {
  color: #3b5998;
  border-color: #3b5998;
  fill: #3b5998;
}
._1rnal:not([disabled]):hover {
  color: #0e76a8;
  border-color: #0e76a8;
  fill: #0e76a8;
}
.-StOJ:not([disabled]):hover {
  color: #00acee;
  border-color: #00acee;
  fill: #00acee;
}
.ZrMho:not([disabled]):hover {
  color: #bd081c;
  border-color: #bd081c;
  fill: #bd081c;
}
._3vRpw {
  display: flex;
  margin-right: 8px;
}
