._1KROU {
  width: 100%;
  display: flex;
  flex-direction: column;
}
._2yJys {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
}
._3B0wC {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(0,0,0,0.5);
}
._45Dt3 {
  width: 100%;
  margin-top: 40px;
  flex: 1;
}
