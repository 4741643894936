._3q7CG {
  max-width: 420px;
  max-height: 264px;
}
._2_Ph8 {
  padding: 40px;
  color: #000;
}
.gUaDK {
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
}
._2k2Sg {
  margin-top: 8px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0,0,0,0.5);
}
._3bRpP {
  padding: 0 40px;
  height: 96px;
  width: 100%;
  border-top: 1px solid #f1f2f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 991px) {
  ._3bRpP {
    padding: 0 24px;
  }
}
._2MJST {
  height: 48px;
  padding: 12px 20px;
  font-size: 16px;
  line-height: 24px;
}
