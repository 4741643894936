._3U9t3 {
  width: 100%;
  display: flex;
  align-items: center;
}
._1iA7L {
  margin-right: 16px;
  width: 48px;
  height: 48px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.25);
  border-radius: 8px;
  transition: fill 0.2s, background-color 0.2s;
}
._2Lm4m {
  fill: #000;
  z-index: 1;
}
._2Lm4m:hover {
  fill: #fff;
  background-color: #000;
}
._2Lm4m > svg {
  transition: transform 0.2s;
}
._2asDo {
  fill: rgba(255,255,255,0.5);
  background-color: #000;
  z-index: 1;
}
._2asDo:hover {
  fill: #fff;
}
._2asDo > svg {
  transition: transform 0.2s;
  transform: rotate(45deg);
}
.s7tZj {
  color: #f7fdc5;
  background-color: transparent;
}
.s7tZj:hover {
  color: #000;
  background-color: #f7fdc5;
}
._3qPcU {
  color: #facad9;
  background-color: transparent;
}
._3qPcU:hover {
  color: #000;
  background-color: #facad9;
}
.f9Skp {
  color: #cfdbf9;
  background-color: transparent;
}
.f9Skp:hover {
  color: #000;
  background-color: #cfdbf9;
}
._2hXGl {
  color: #cfdbf9;
  background-color: transparent;
}
._2hXGl:hover {
  color: #000;
  background-color: #cfdbf9;
}
._1ZzcB {
  margin-top: 8px;
  position: absolute;
  top: 56px;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  opacity: 0;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
._1iA7L:hover > ._1ZzcB {
  opacity: 1;
}
._1iA7L:hover > ._2iDjN {
  color: #f7fdc5;
}
._1iA7L:hover > .uqYi2 {
  color: #facad9;
}
._1iA7L:hover > ._1xOtE {
  color: #cfdbf9;
}
._1iA7L:hover > ._3H2q7 {
  color: #cfdbf9;
}
.ZaUy5 {
  transition: 0.2s;
  display: none;
}
.ZaUy5 > * {
  margin-right: 32px;
}
.Z0vY3 {
  display: flex;
}
.U4BSJ {
  width: 16px;
  height: 16px;
}
._32SdE {
  width: 20px;
  height: 20px;
}
._7vwPp {
  font-weight: bold;
  font-size: 18px;
}
._4Dj2u {
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: opacity 0.2s;
  color: #fff;
}
._1ccRY {
  display: none;
}
