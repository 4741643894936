._3TMn8 {
  width: 1070px;
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
@media (max-width: 1365px) {
  ._3TMn8 {
    width: 601px;
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  ._3TMn8 {
    width: 570px;
  }
}
@media (max-width: 767px) {
  ._3TMn8 {
    width: 410px;
  }
}
@media (max-width: 575px) {
  ._3TMn8 {
    max-width: 410px;
    width: auto;
    min-width: 272px;
  }
}
.YHFV1 {
  width: 344px !important;
}
@media (max-width: 1365px) {
  .YHFV1 {
    width: 291px !important;
  }
  .YHFV1:last-child {
    margin-top: 13px;
    width: 100% !important;
  }
}
@media (max-width: 991px) {
  .YHFV1 {
    margin-top: 13px;
    width: 275px !important;
  }
}
@media (max-width: 767px) {
  .YHFV1 {
    width: 100% !important;
  }
}
._3TTep {
  background: #0c0c0c !important;
  color: #fff !important;
}
._3TTep:hover {
  background: #131313 !important;
}
._3TTep:focus-within {
  background: #131313 !important;
}
._3TTep:focus-within input::-webkit-input-placeholder {
  opacity: 1 !important;
  color: rgba(255,255,255,0.3);
}
._3TTep:focus-within input:-ms-input-placeholder {
  opacity: 1 !important;
  color: rgba(255,255,255,0.3);
}
._3TTep:focus-within input::-ms-input-placeholder {
  opacity: 1 !important;
  color: rgba(255,255,255,0.3);
}
._3TTep:focus-within input::placeholder {
  opacity: 1 !important;
  color: rgba(255,255,255,0.3);
}
._3TTep input::-webkit-input-placeholder {
  opacity: 1 !important;
  color: inherit;
}
._3TTep input:-ms-input-placeholder {
  opacity: 1 !important;
  color: inherit;
}
._3TTep input::-ms-input-placeholder {
  opacity: 1 !important;
  color: inherit;
}
._3TTep input::placeholder {
  opacity: 1 !important;
  color: inherit;
}
