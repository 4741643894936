._36m0B {
  padding-top: 20px;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
  max-height: 90%;
}
._37VAZ {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
