.vj5wu {
  display: flex;
  flex-direction: column;
  color: #fff;
}
.PNIiK {
  padding-top: 78%;
}
.FRq3y {
  margin-bottom: 8px;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  transition: all 200ms ease-in-out;
}
.FRq3y:hover ._4e2NL {
  visibility: visible;
}
._2r6HO {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 2;
  padding: 4px;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  overflow: hidden;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: rgba(0,0,0,0.45);
  border-radius: 4px;
}
._3clVG {
  display: flex;
  align-items: center;
  text-align: center;
}
._2r6HO:hover ._2ONc0 {
  visibility: visible;
  opacity: 1;
  width: auto;
  position: static;
}
._2ONc0 {
  visibility: hidden;
  opacity: 0;
  width: 0;
  position: absolute;
  margin-left: 8px;
  margin-right: 4px;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
._3_WkX {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
._4e2NL {
  visibility: hidden;
  color: transparent;
  background: rgba(0,0,0,0.25);
}
@media (hover: none) {
  ._4e2NL {
    visibility: visible;
  }
}
._4e2NL:hover {
  background-color: #000;
}
._1-8Sd {
  color: #fff;
}
._2FM4M {
  padding: 0 20px 20px;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  color: inherit;
  font-size: 14px;
  font-weight: 600;
}
.Mo8S_ {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}
._3hLza {
  z-index: 0;
  height: 100%;
  background-size: cover;
  background-position: center;
}
._3cD3a {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, transparent, transparent 45%, rgba(0,0,0,0.8));
}
.FRq3y:active {
  transform: scale(0.96);
}
.FRq3y:hover .Mo8S_ {
  transform: scale(1.1);
}
.L8zi6 {
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: inherit;
  cursor: pointer;
}
._3UD7s {
  margin-right: 8px;
  width: 24px;
  height: 24px;
  border: 0;
}
._2tAHa {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
