._39PXE {
  margin-left: auto;
  width: 160px;
}
@media (max-width: 575px) {
  ._39PXE {
    margin-left: 0;
    width: 100%;
    margin-bottom: 16px;
  }
}
