._3vl46 {
  width: 160px;
}
._24W2B {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.aXwwl {
  margin-bottom: 30px;
}
._1rAnK {
  justify-content: center;
}
