._2EA2t {
  z-index: 0;
  padding: 90px 100px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
@media (max-width: 1365px) {
  ._2EA2t {
    padding: 60px;
  }
}
@media (max-width: 991px) {
  ._2EA2t {
    padding: 0;
  }
}
.lgafC {
  font-size: 36px;
  line-height: 48px;
  max-width: 45ch;
  font-weight: bold;
  margin-bottom: 24px;
}
@media (max-width: 991px) {
  .lgafC {
    margin-bottom: 0;
  }
}
@media (max-width: 575px) {
  .lgafC {
    font-size: 22px;
    line-height: 32px;
  }
}
._5MvsX {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
@media (max-width: 991px) {
  ._5MvsX {
    display: none;
  }
}
._3_chr {
  flex-grow: 0;
  font-size: 16px;
  line-height: 24px;
}
._3_chr ul > li:not(:last-child) {
  margin-bottom: 24px;
}
._3_chr > ul {
  list-style: disc inside url("/static/images/rfp/list-bullet.svg");
}
._1bOrp ul > li:not(:last-child),
._2R2xM ul > li:not(:last-child) {
  margin-bottom: auto;
}
@media (max-width: 991px) {
  ._3_chr {
    display: none;
  }
}
.EecDk {
  margin-top: 40px;
  min-width: 416px;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.EecDk > img {
  max-width: 540px;
  max-height: 460px;
  width: 100%;
}
@media (max-width: 991px) {
  .EecDk {
    display: none;
  }
}
