._2w7Ld {
  width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
._2yY8M {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
._2iSPT {
  opacity: 0.2;
  color: #70ac5b;
}
._12E3s {
  margin: 24px 0;
}
._3-Lzr {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 4px;
}
._1BiA3 {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0,0,0,0.45);
}
._25Yoz {
  font-weight: normal;
}
