._2Smb5 {
  max-width: 560px;
  height: auto;
  max-height: 95vh;
}
@media (max-width: 575px) {
  ._2Smb5 {
    padding: 0;
    max-width: 327px;
    max-height: 85vh;
  }
}
._2dCO- {
  height: 80px;
  margin: 0 40px;
  border-bottom: 1px solid #f1f2f2;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  color: #000;
}
@media (max-width: 575px) {
  ._2dCO- {
    height: 64px;
    margin: 0 20px;
  }
}
.D60gP {
  min-width: auto;
  width: 22px;
  height: 22px;
  padding: 0;
  border: none;
  margin-right: 12px;
}
.XBqOf {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
._3rA7w {
  padding: 0;
  font-size: 14px;
  line-height: 20px;
}
