._2emgo {
  background-color: #f1f2f2;
  border-radius: 8px;
}
._11fMb {
  background: #fff;
  border: 1px solid rgba(0,0,0,0.1);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
._3TV-L {
  cursor: default;
}
._Gqcx {
  font-size: 16px;
  color: #000;
}
._3tS9R {
  padding: 16px 24px;
  font-size: 14px;
  line-height: 20px;
  color: #888;
}
._1uhZs {
  min-width: auto;
  margin-top: 16px;
  height: 20px;
  padding: 0px;
  background-color: inherit;
}
._1uhZs:not(:last-child) {
  margin-right: 16px;
}
._1uhZs > a {
  color: #000;
}
._3EGm_ {
  margin-top: 8px;
  color: #000;
}
