._1ZzfM {
  border-radius: 8px;
  background: rgba(0,0,0,0.3);
}
.hyArF:not(._36IQ0) {
  border: 1px dashed #f7fdc5;
  background-color: #131313;
}
.scamO {
  font-size: 16px;
  line-height: 24px;
}
._2s66S {
  margin-top: 16px;
}
