._3qDvZ {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  margin: 0 auto;
  padding: 0 40px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  z-index: 5;
}
@media (max-width: 767px) {
  ._3qDvZ {
    border-bottom: 1px solid rgba(255,255,255,0.12);
  }
}
@media (max-width: 575px) {
  ._3qDvZ {
    padding: 0 24px;
  }
}
._2mWW4 {
  display: flex;
  align-items: center;
}
._2PMJX {
  margin-left: 60px;
  border: none;
}
@media (max-width: 575px) {
  ._2PMJX {
    display: none;
  }
}
.Qe9I0,
._1hQal {
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 575px) {
  .Qe9I0,
  ._1hQal {
    padding: 0;
    min-width: auto;
    width: 32px;
    height: 32px;
    border-radius: 8px;
  }
}
._2BVIO {
  margin-right: 4px;
}
._1f3HA {
  margin-right: 6px;
}
@media (max-width: 575px) {
  ._2BVIO,
  ._1f3HA {
    margin-right: 0;
  }
}
.lJQvR {
  margin-right: 12px;
}
.lJQvR:last-child {
  margin-right: 0;
}
._3iBE1 {
  margin-right: 16px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid rgba(255,255,255,0.53);
}
.XQuud {
  width: 100%;
  height: 100%;
}
._1FQSb {
  margin-right: 8px;
}
._2J9is {
  display: flex;
  align-items: center;
}
