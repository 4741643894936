._36-wK {
  max-width: 660px;
  max-height: 660px;
  color: #000;
  font-size: 14px;
  line-height: 20px;
  padding: 0;
}
@media (max-width: 767px) {
  ._36-wK {
    max-width: 410px;
    max-height: 100%;
  }
}
@media (max-width: 575px) {
  ._36-wK {
    min-width: 304px;
  }
}
._2cvfA {
  top: 40px;
}
.QhJZ7 {
  padding: 24px 40px;
}
@media (max-width: 767px) {
  .QhJZ7 {
    padding: 24px;
  }
}
.itRtw {
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  opacity: 1;
  padding: 0;
}
._21fNp {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
  text-align: center;
  color: rgba(0,0,0,0.5);
}
._1z5JI {
  width: 100%;
  border-radius: 8px;
  height: 48px;
  font-size: 16px;
}
._2N7qa {
  display: flex;
}
.juM-P {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: rgba(0,0,0,0.75);
  flex: 1;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
}
._1kKdW {
  margin-bottom: 16px;
}
._1kKdW:last-child {
  margin-bottom: 0;
}
.zudIF {
  padding: 32px 40px;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 8px;
  margin-bottom: 24px;
}
.zudIF:hover {
  border-color: rgba(0,0,0,0.1);
  box-shadow: none;
}
._1cVsO {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
._2UIK2 {
  padding: 12px 20px;
  font-size: 16px;
  height: 48px;
}
._3AZiM {
  font-size: 14px;
  margin-top: 40px;
  line-height: 20px;
  text-align: center;
  color: rgba(0,0,0,0.5);
}
._3AZiM > a {
  color: #000;
  border-bottom: 1px solid #000;
}
._3qW5n > a {
  color: #fff;
  margin-right: 4px;
}
