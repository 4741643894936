._1Kl8G {
  display: flex;
  flex-direction: column;
}
._1uloz {
  width: 420px;
  height: 317.02px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  background-color: #000;
}
@media (max-width: 1365px) {
  ._1uloz {
    width: 375px;
    height: 283px;
  }
}
@media (max-width: 991px) {
  ._1uloz {
    width: 344px;
    height: 260px;
  }
}
@media (max-width: 767px) {
  ._1uloz {
    width: 323px;
    height: 244px;
  }
}
@media (max-width: 575px) {
  ._1uloz {
    width: 269px;
    height: 203px;
  }
}
._191i0 {
  margin-top: 6px;
  display: flex;
  align-items: center;
}
.ieSR8 {
  margin-left: 8px;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #000;
}
