._3YFvi {
  color: #000;
  padding: 0;
}
._2Tu99 {
  margin-bottom: 40px;
}
._1wwVM {
  margin-bottom: 8px;
}
._3kS35 {
  text-align: center;
  margin-bottom: 16px;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
._3ec1Q {
  color: #70ac5b;
}
._32SnH {
  padding: 0;
  border: none;
  display: inline;
  background-color: inherit;
  color: inherit;
  text-decoration: underline;
}
._32SnH:hover {
  cursor: pointer;
}
._3vofj {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0,0,0,0.5);
}
._3QUnB {
  color: #000;
}
