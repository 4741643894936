._1HgJu {
  padding: 32px 40px;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._1jb5L {
  color: #000;
}
._1jb5L:hover {
  border-color: #000;
  box-shadow: inset 0 0 0px 1px #000;
}
.ac-Li {
  color: #fff;
}
._3n2Ry {
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
._3nfJ8 {
  color: rgba(0,0,0,0.5);
}
._1F0kU {
  color: rgba(255,255,255,0.75);
}
.te50F {
  font-size: 22px;
  line-height: 32px;
}
._1UhxX {
  color: #cfdbf9;
}
._3liBs {
  color: #bbdcb0;
}
.IyhW3 {
  display: flex;
  margin-bottom: 24px;
  flex-direction: column;
}
.aLJiv {
  margin-right: 20px;
  margin-bottom: 24px;
}
._3-WsG {
  margin-right: 16px;
  font-size: 48px;
  line-height: 48px;
}
._2pc_v {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 568px) {
  ._2pc_v {
    min-height: 100px;
  }
}
._2nXQY {
  margin-bottom: 16px;
}
.Nvm92 {
  margin-top: 24px;
}
._23Wor {
  color: rgba(0,0,0,0.5);
}
._1Y40t {
  color: rgba(255,255,255,0.75);
}
._2nXQY ul {
  list-style: inside disc;
}
._2nXQY ul> li {
  font-size: 14px;
  line-height: 20px;
}
._2F1kD {
  height: 48px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
}
._2uuCj {
  background-color: #cfdbf9;
}
._2_Uol {
  background-color: #bbdcb0;
}
