._1kp5z {
  padding-top: 40px;
  min-height: calc(100vh - 128px);
  justify-content: center;
}
.kX5fy {
  width: 32px;
  height: 24px;
  margin-right: 12px;
}
.kX5fy:last-child {
  margin-right: 0;
}
._2kt3L {
  margin: 0 auto;
  max-width: 1070px;
  width: 100%;
}
._2mDJR {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.cGKfQ {
  margin-right: 23px;
  font-size: 36px;
  line-height: 48px;
  flex-shrink: 0;
}
@media (max-width: 575px) {
  .cGKfQ {
    margin-bottom: 8px;
  }
}
._1y2_L {
  max-width: 416px;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255,255,255,0.75);
  margin-bottom: 24px;
}
._1y2_L:last-child {
  margin-bottom: 0;
}
._18Azp {
  margin-top: 92px;
  width: 100%;
  max-width: 1288px;
  flex: 1;
}
@media (max-width: 991px) {
  ._18Azp {
    margin-top: 40px;
  }
}
