._1GS20 {
  display: flex;
}
._1c4kt {
  margin-right: 7px;
  min-width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e3e3e3;
  border-radius: 4px;
  transition: all 0.1s ease-in-out;
}
._1I-1Y {
  display: none;
}
.lfleS {
  display: none;
}
._1I-1Y:checked + ._1c4kt {
  background: #bbdcb0;
}
._1I-1Y:checked + ._1c4kt > .lfleS {
  display: block;
}
._1GS20:hover > ._1c4kt {
  background: #000;
}
._1G1nQ {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0,0,0,0.45);
}
