._1r1_H {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: rgba(0,0,0,0.5);
}
._28BxN {
  margin-bottom: 8px;
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
}
._2ehuH {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 24px;
}
.kKCuC {
  width: 320px;
  height: 404px;
  border-radius: 8px;
}
._3RBNe {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
}
._1Zk1w {
  color: rgba(0,0,0,0.5);
  margin-top: 8px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
.iZdb0 {
  margin-top: 24px;
  width: 100%;
  display: flex;
}
._2qFLk {
  margin-top: 20px;
  margin-bottom: 32px;
  width: 66px;
  height: 112px;
}
.iZdb0 {
  justify-content: center;
}
._38wN5 {
  margin-top: 26px;
  flex-basis: 66.6%;
}
