._3glNi {
  background: transparent;
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}
._3glNi:hover,
._3glNi:focus {
  background-color: rgba(0,0,0,0.1);
}
._3glNi:active {
  background-color: rgba(0,0,0,0.25);
}
