._2iVxf {
  --ck-color-base-background: rgba(255,255,255,0.1);
  --ck-custom-color-content: #fff;
  --ck-custom-color-heading: #fff;
  --ck-color-text: #fff;
  --ck-color-toolbar-background: rgba(255,255,255,0.1);
  --ck-color-button-default-background: #131313;
  --ck-color-button-default-hover-background: #202020;
  --ck-color-list-button-hover-background: #202020;
  --ck-color-button-default-disabled-background: #131313;
  width: 100%;
}
.eBXyz {
  border: 1px solid #bf3517;
  border-radius: 8px;
}
