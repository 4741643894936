._2X50t {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.qvj6C {
  width: 100%;
}
._3c5n4 {
  margin-top: -1px;
  border-top: 1px solid rgba(255,255,255,0.1);
  border-bottom: none;
  color: rgba(255,255,255,0.3);
}
.y5280 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 51px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  color: currentColor;
  cursor: pointer;
}
.y5280:hover:not(._3M_PF) {
  color: #bbdcb0;
}
._3M_PF {
  color: #70ac5b;
}
