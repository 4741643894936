.bMjcu {
  height: 100%;
  width: 100%;
  padding: 60px 0;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  .bMjcu {
    padding: 40px 0;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
