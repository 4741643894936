._3H9Ed {
  max-width: 352px;
  max-height: 280px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
}
._1maDX {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}
._1DH70 {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0,0,0,0.5);
}
._2ctWN {
  margin-top: 32px;
  display: flex;
}
.VFr_z {
  height: 48px;
}
.VFr_z:not(:last-child) {
  margin-right: 12px;
}
.VFr_z:last-child {
  flex: 1;
}
