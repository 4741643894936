._17t2C {
  max-width: 560px;
  height: 420px;
}
._14bUt {
  height: 80px;
  margin: 0 40px;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f1f2f2;
}
._1ShY6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000;
}
._2eDyc {
  padding: 40px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0,0,0,0.5);
}
._2eDyc > ol {
  list-style: decimal;
  -webkit-padding-start: 24px;
          padding-inline-start: 24px;
}
._2eDyc > ol > li:not(:last-child) {
  margin-bottom: 20px;
}
._2eDyc a {
  color: #000;
}
