._1_eCC {
  min-height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
}
._3bWbk {
  font-size: 14px;
  line-height: 20px;
}
._3bWbk > a {
  color: inherit;
  text-decoration: underline;
}
.WFQ5p {
  margin-top: 24px;
  align-items: center;
}
._2ruJj {
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #000;
}
._3jS7W {
  color: #000;
  text-decoration: underline;
}
._27zko {
  color: #fff;
}
._3L0h2:checked + ._27zko {
  background: #000;
}
._3b4hV {
  max-width: 848px;
  width: 100%;
}
@media (max-width: 1365px) {
  ._3b4hV {
    max-width: 756px;
  }
}
@media (max-width: 991px) {
  ._3b4hV {
    max-width: 649px;
  }
}
@media (max-width: 767px) {
  ._3b4hV {
    max-width: 324px;
  }
}
@media (max-width: 575px) {
  ._3b4hV {
    max-width: 270px;
  }
}
._2G9qa {
  margin-top: 40px;
  margin-bottom: 80px;
  width: 100%;
}
._11_CI {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}
._1yfCE {
  max-width: 634px;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
}
._1O91J {
  max-width: 290px;
}
.x_a-I {
  max-width: 290px;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .x_a-I {
    margin-bottom: 0;
  }
}
.y0Ytu {
  display: flex;
  flex-direction: column;
}
._66AAA {
  display: flex;
}
@media (max-width: 767px) {
  ._66AAA {
    flex-direction: column;
    align-items: flex-start;
  }
}
._3SR3- {
  max-width: 307px;
  width: 100%;
}
@media (max-width: 767px) {
  ._3SR3- {
    max-width: 100%;
  }
}
.H5ZIO {
  margin-left: auto;
}
@media (max-width: 767px) {
  .H5ZIO {
    margin-top: 32px;
    margin-left: 0;
    max-width: 100%;
  }
}
._1xUIU {
  display: flex;
  align-items: center;
}
.UfFgT {
  flex-shrink: 0;
}
._3YwPL {
  margin-left: 12px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}
._2gek9 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.eXRmp {
  margin-top: 32px;
}
.gPCHe {
  margin-top: 64px;
  margin-bottom: 64px;
}
@media (max-width: 991px) {
  .gPCHe {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
