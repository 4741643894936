._3TaHD {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 6px 20px;
  border: none;
  border-color: #eceee3;
  border-radius: 20px;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  color: #afb599;
  background-color: transparent;
  transition: color 0.35s;
}
a._3TaHD {
  line-height: calc(44px - (6px * 2));
}
._1KHvG {
  height: 36px;
  padding-right: 16px;
  padding-left: 16px;
}
._2exxu {
  color: #474b2f;
}
._2exxu:hover {
  transition-duration: 0.175s;
  color: #a1a490;
}
@media (hover: hover) {
  .aXFTf {
    transition: color 0.35s, background-color 0.35s;
  }
  .aXFTf:before {
    content: '';
    display: block;
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: transform 0.35s, background 0.35s;
    will-change: transform;
  }
  .aXFTf:hover {
    color: #474b2f;
  }
  .aXFTf:hover:before {
    background-color: #eceee3;
  }
  .aXFTf:active:before {
    transform: scale(0.95);
  }
}
._1Uy3h:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: '';
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-radius: inherit;
  background-color: transparent;
  transition: background-color 0.35s, transform 0.35s;
  transform: translateZ(0);
  will-change: transform;
}
@media (hover: hover) {
  ._1Uy3h:hover {
    color: #474b2f;
  }
  ._1Uy3h:hover:before {
    background-color: #eceee3;
  }
}
._1Uy3h:active:before {
  transform: scale(0.95);
}
.AJhdJ {
  cursor: default;
  color: #bfb7b6;
  background-color: $color-bg;
}
._3eHKD {
  background-color: #fff;
  box-shadow: 0 2px 2px 0 #ebebeb;
  transition: box-shadow 0.35s;
}
@media (hover: hover) {
  ._3eHKD:hover {
    box-shadow: 0 5px 10px 0 rgba(165,165,165,0.22);
  }
  ._3eHKD:active {
    box-shadow: inherit;
  }
}
._1qX1w,
.PIOPR {
  border-radius: 8px;
  width: 100%;
  color: #000;
}
._1qX1w {
  background-color: #bbdcb0;
}
.PIOPR {
  background-color: #dbd6eb;
}
._390g6 {
  display: inline-flex;
  align-items: center;
}
@media (max-width: 567px) {
  ._390g6 ._143aj {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.Z1hkO .S_c3Q {
  transform: rotate(180deg);
  order: -1;
}
._143aj {
  width: 100%;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.S_c3Q {
  margin-left: 4px;
}
.Z1hkO .S_c3Q {
  margin-right: 4px;
  margin-left: 0;
}
.S_c3Q svg {
  vertical-align: text-top;
}
