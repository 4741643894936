._1NHAP {
  width: 100%;
  max-width: 1070px;
  height: 100%;
  position: relative;
  border-radius: 8px;
  color: #000;
  background-color: #bbdcb0;
}
._1HWDc {
  width: 100%;
  min-height: 760px;
  height: calc(100vh - 64px);
  padding: 64px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1365px) {
  ._1HWDc {
    padding: 64px 40px;
  }
}
@media (max-width: 991px) {
  ._1HWDc {
    padding: 24px 0;
  }
}
@media (max-width: 767px) {
  ._1HWDc {
    padding: 24px 40px;
    min-height: 560px;
  }
}
@media (max-width: 575px) {
  ._1HWDc {
    padding: 8px;
  }
}
._1NHAP > * {
  width: 100%;
  height: 100%;
}
@media (max-width: 1365px) {
  ._1NHAP {
    max-width: 912px;
  }
}
@media (max-width: 991px) {
  ._1NHAP {
    max-width: 570px;
    height: 100%;
    overflow: hidden;
  }
}
@media (max-width: 767px) {
  ._1NHAP {
    max-width: 496px;
    height: 100%;
    overflow: hidden;
  }
}
._1Mxhz {
  width: 100%;
  height: 100%;
  padding: 24px 40px;
  display: flex;
}
@media (max-width: 1365px) {
  ._1Mxhz {
    padding: 24px 16px;
  }
}
@media (max-width: 991px) {
  ._1Mxhz {
    padding: 24px 40px 40px;
  }
}
@media (max-width: 767px) {
  ._1Mxhz {
    padding: 16px 16px 40px;
  }
}
@media (max-width: 575px) {
  ._1Mxhz {
    padding: 16px 8px;
  }
}
._12s-7 {
  height: 100%;
  width: 400px;
  flex-shrink: 0;
  padding-right: 40px;
}
@media (max-width: 1365px) {
  ._12s-7 {
    width: 376px;
    padding-right: 16px;
  }
}
@media (max-width: 991px) {
  ._12s-7 {
    width: 100%;
    padding-right: 0;
  }
}
.UK6Hk {
  width: 100%;
  padding-right: 0;
}
._7g-Mp {
  width: 600px;
  height: 500px;
}
@media (max-width: 767px) {
  ._7g-Mp {
    width: 324px;
    height: 268px;
  }
}
@media (max-width: 575px) {
  ._7g-Mp {
    width: 312px;
    height: 258px;
  }
}
._2G32M {
  z-index: 1;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}
._2vJFY {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 991px) {
  ._2vJFY {
    display: none;
  }
}
@media (max-width: 991px) {
  ._1O5G8 {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    display: block;
    width: 570px;
  }
}
@media (max-width: 767px) {
  ._1O5G8 {
    width: 496px;
  }
}
@media (max-width: 575px) {
  ._1O5G8 {
    width: 100%;
  }
}
.wVUyC {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
._1Lv3r {
  min-width: auto;
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  border-radius: 6px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
._1Lv3r:not(:last-child) {
  margin-right: 4px;
}
._1Lv3r > a {
  display: flex;
}
._1jpNA {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._8aibl {
  margin-left: auto;
  display: flex;
  align-items: center;
}
._2DSPO {
  height: 178px;
  text-align: center;
}
.e6bnS {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 8px;
}
._1q9CB {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}
._3IdPj {
  padding-top: 20px;
  border-radius: 8px;
  background-color: #fff;
  overflow-y: auto;
  max-height: 90%;
}
._3Xnwa {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
._2D8vW {
  margin-bottom: 24px;
  display: flex;
}
._2JFx2 {
  height: 40px;
  flex: 1;
  border: none;
  border-radius: 37px;
  font-size: 10px;
  line-height: 16px;
  color: rgba(0,0,0,0.5);
}
._2JFx2:not(:last-child) {
  margin-right: 4px;
}
._2JFx2:not([disabled]):hover,
._2JFx2:not([disabled]):focus {
  background: rgba(0,0,0,0.05);
}
._2JFx2:not([disabled]):active {
  box-shadow: inset 0px 0px 0 1px rgba(0,0,0,0.1);
  background: rgba(0,0,0,0.1);
}
._2qnQI {
  box-shadow: inset 0px 0px 0 1px rgba(0,0,0,0.1);
  color: #000;
}
._2qnQI:not([disabled]):hover {
  color: inherit;
}
._1vjdV {
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
._2Go7D {
  height: 64px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._2Go7D * {
  stroke: inherit;
  fill: rgba(0,0,0,0.25);
}
._2Go7D:last-child {
  margin-bottom: 20px;
}
._2Go7D:hover {
  background-color: rgba(207,219,249,0.2);
}
._2Go7D:active,
._2Go7D:focus,
._10war {
  background-color: #cfdbf9;
  outline: none;
}
._3CJTl * {
  fill: inherit;
}
._3CJTl .cUvZf {
  background-color: #bbdcb0;
}
._3CJTl ._1_8Xb {
  color: inherit;
}
._3CJTl ._2GB8P {
  opacity: 1;
}
._28Kkb {
  width: 40px;
  height: 40px;
}
._2GB8P {
  position: relative;
  margin-right: 12px;
  opacity: 0.5;
}
.cUvZf {
  background-color: #cacaca;
}
._1_8Xb {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0,0,0,0.25);
}
._254ZT {
  margin-left: auto;
  color: rgba(0,0,0,0.25);
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
._1HJnT {
  width: 590px;
  height: calc(100% + 128px);
  overflow-y: auto;
  border-radius: 8px;
  margin-top: -64px;
  padding: 40px 40px 0;
  position: relative;
  background: #fff;
}
@media (max-width: 1365px) {
  ._1HJnT {
    width: 504px;
  }
}
@media (max-width: 991px) {
  ._1HJnT {
    margin-top: 0;
    height: 100%;
    width: 100%;
  }
}
@media (max-width: 575px) {
  ._1HJnT {
    padding: 16px 16px 0;
  }
}
._2bkMn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Jiga8 {
  display: none;
  position: absolute;
  right: 16px;
  top: 16px;
  background-color: #000;
}
@media (max-width: 991px) {
  ._2ql0p {
    display: flex;
  }
}
._1CWfm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
._36Xlk {
  display: block;
  margin-bottom: 32px;
}
.y0suO {
  width: 196px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(0,0,0,0.25);
}
.yAlZs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
._1rzqR {
  width: 120px;
  height: 120px;
}
._2vVPh {
  margin-top: 24px;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
}
._1s9yJ {
  font-size: 14px;
  line-height: 20px;
}
._1s9yJ > span {
  color: rgba(0,0,0,0.5);
}
._3sLXR {
  margin-top: 24px;
  width: 360px;
}
@media (max-width: 575px) {
  ._3sLXR {
    width: 100%;
  }
}
._3sLXR > input {
  text-transform: none;
  font-size: 16px;
  line-height: 24px;
}
._2mrhM {
  height: 65%;
}
._1G1SP {
  z-index: 1;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
._1hVkV {
  width: 100%;
  border-bottom: 1px solid #f1f2f2;
  margin: 24px 0;
}
._36vOl {
  margin: 48px 0;
}
._1OLah {
  padding: 0 40px;
}
@media (max-width: 991px) {
  ._1OLah {
    padding: 0;
  }
}
.pKbMb {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.fK3xK {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0,0,0,0.25);
}
._2W3HD {
  font-size: 16px;
  line-height: 20px;
  word-break: break-word;
}
._2cJO8 {
  color: rgba(0,0,0,0.25);
}
._1Wpt7 {
  text-decoration: underline;
}
._3fwAF {
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ZNuSz {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
._33Hwh {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
._2cUEX {
  width: 48px;
  height: 48px;
  opacity: 0.2;
}
