._27uiG {
  margin-top: 18px;
  color: rgba(255,255,255,0.5);
}
._19JJp {
  height: 30px;
  background: rgba(48,141,13,0.2);
  border-radius: 6px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._3-Swx {
  height: 30px;
  position: absolute;
  left: 12px;
  display: flex;
  align-items: center;
}
._1WBAW {
  position: relative;
  margin-bottom: 24px;
  height: 30px;
  background: rgba(255,255,255,0.1);
  border-radius: 6px;
}
._1zSnN {
  display: flex;
  align-items: center;
}
.EpJLB {
  margin-right: 14px;
  min-width: 28px;
  min-height: 28px;
  width: auto !important;
  height: auto !important;
}
.EpJLB:before {
  min-width: 28px;
  min-height: 28px;
  width: auto !important;
  height: auto !important;
  background-color: transparent !important;
}
._1Y7ub {
  flex: 1;
  margin-right: 8px;
}
