._2dBBB {
  width: 100%;
  height: 100%;
  position: relative;
}
._18Z3k {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
._34i-4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
