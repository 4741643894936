._1skzh {
  width: 100%;
  margin-top: 24px;
  overflow-x: auto;
  display: flex;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 200ms;
}
@media (max-width: 767px) {
  ._1skzh {
    flex-direction: column;
    margin-bottom: 24px;
    padding: 0 40px;
  }
}
@media (max-width: 575px) {
  ._1skzh {
    padding: 0 24px;
  }
}
._1skzh:hover ._3BZAS {
  display: block;
}
._186Ez {
  padding-left: 40px;
}
.ys5FM {
  position: absolute;
  left: 0;
  width: 100%;
  height: calc(100vh - 64px);
  z-index: 2;
  overflow-y: auto;
  background-color: #000;
}
._3BZAS {
  display: none;
  position: absolute;
  top: 0;
  z-index: 10;
  height: 100%;
  border: none;
  cursor: pointer;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 200ms;
}
._2ABlK {
  background: linear-gradient(270deg, #000 0%, #000 19.27%, rgba(0,0,0,0) 100%);
  left: auto;
  right: 0;
}
.DL0aV {
  background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 19.27%, #000 100%);
  left: 0;
  right: auto;
}
._3BZAS:hover > .N_DXB {
  fill: #fff;
}
.N_DXB {
  fill: rgba(255,255,255,0.5);
}
._1kHcn {
  width: auto;
  margin-right: 16px;
}
._1kHcn:last-child {
  margin-right: 40px;
}
@media (max-width: 767px) {
  ._1kHcn {
    width: 100%;
    margin-right: 0;
    margin-bottom: 8px;
  }
}
.Cx3c_ {
  height: 44px;
  padding: 12px 16px;
  background: rgba(255,255,255,0.12);
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  width: auto;
  transition: all cubic-bezier(0.645, 0.045, 0.355, 1) 200ms;
}
@media (max-width: 767px) {
  .Cx3c_ {
    width: 100%;
  }
}
.Cx3c_:hover {
  background: rgba(255,255,255,0.3);
}
.LkB6K {
  background: #bbdcb0;
  color: #000;
}
.LkB6K:hover {
  background: linear-gradient(0deg, rgba(0,0,0,0.16), rgba(0,0,0,0.16)), #bbdcb0;
}
