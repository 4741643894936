.H1XVT {
  margin: 0 32px;
  margin-top: 24px;
  min-height: 48px;
  padding: 12px 50px 12px 16px;
  border-radius: 8px;
  font-size: 16px;
  line-height: 24px;
  background: #f1f2f2;
  color: rgba(0,0,0,0.25);
}
._2GwbA {
  display: inline-block;
}
.CBoP_ {
  height: 48px;
}
._2Y_U6 {
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.WXgSK {
  margin: 0 32px;
  margin-top: 24px;
  display: flex;
}
._2QYq8 {
  width: 130px;
  padding: 8px 8px 8px 16px;
  border: 1px solid rgba(0,0,0,0.1);
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
}
._2QYq8:not(:last-child) {
  margin-right: 4px;
}
