._1rOOC {
  margin-top: 52px;
  margin-bottom: 64px;
  width: 100%;
  display: flex;
  color: #000;
}
@media (max-width: 991px) {
  ._1rOOC {
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  ._1rOOC {
    display: block;
  }
}
@media (max-width: 575px) {
  ._1rOOC {
    margin-top: 16px;
  }
}
._21Nyt {
  display: flex;
  flex-wrap: wrap;
}
.eK1mV {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 100%;
  max-width: 380px;
}
.eK1mV:not(:last-child) {
  margin-right: 20px;
}
@media (max-width: 1365px) {
  .eK1mV {
    max-width: 291px;
  }
}
@media (max-width: 991px) {
  .eK1mV {
    max-width: 334px;
  }
}
@media (max-width: 767px) {
  .eK1mV {
    max-width: none;
    margin-bottom: 40px;
  }
}
._2LkH1 {
  flex-shrink: 0;
  width: 100%;
}
._2LkH1:not(:last-child) {
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  ._2LkH1:not(:last-child) {
    margin-bottom: 40px;
  }
}
._2pBeT {
  display: grid;
  grid-template-columns: repeat(2, 380px);
  grid-template-rows: -webkit-max-content;
  grid-template-rows: max-content;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}
@media (max-width: 1365px) {
  ._2pBeT {
    grid-template-columns: repeat(2, 291px);
  }
}
@media (max-width: 991px) {
  ._2pBeT {
    max-width: 334px;
    width: 100%;
    display: flex;
    flex-flow: column;
  }
}
@media (max-width: 767px) {
  ._2pBeT {
    max-width: none;
  }
}
._2FaXC {
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.DwzOj {
  padding: 0;
  padding-right: 8px;
  padding-left: 4px;
  margin: 0;
  margin-top: 16px;
  height: 32px;
  display: flex;
  border-radius: 6px;
  background: none;
  font-weight: normal;
  font-size: 14px;
  align-items: center;
}
.DwzOj:hover {
  background: rgba(0,0,0,0.1);
}
.ikQj3 {
  margin-right: 4px;
  transform: rotate(-180deg);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._17BIa {
  transform: none;
}
