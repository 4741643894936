._2kEfi {
  cursor: pointer;
  flex: 1;
  display: flex;
  align-items: center;
  height: 72px;
  max-width: calc(100% - 72px);
  padding-left: 24px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background: #bbdcb0;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._3svRT {
  max-width: calc(100% - 120px);
}
._3svRT ._2q10l {
  max-width: calc(100% - 52px);
}
@media (max-width: 767px) {
  ._3svRT {
    max-width: calc(100% - 104px);
  }
}
._2kEfi:hover {
  background: #9dc98e;
}
._2kEfi:hover ._3oz4n {
  opacity: 1;
}
.m_dAh {
  display: flex;
}
.m_dAh:not(:last-child) {
  margin-bottom: 12px;
}
@media (max-width: 575px) {
  .m_dAh:not(:last-child) {
    margin-bottom: 8px;
  }
}
._2A8pd {
  margin-right: 10px;
  flex-shrink: 0;
}
@media (max-width: 767px) {
  ._2A8pd {
    align-self: flex-start;
    display: none;
  }
}
._2q10l {
  display: flex;
  align-items: center;
  flex-shrink: 1;
  flex-wrap: wrap;
  max-width: 100%;
}
._1tyou {
  margin-right: 8px;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media (max-width: 767px) {
  ._1tyou {
    width: 100%;
    font-size: 14px;
  }
}
._2zVS2 {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0,0,0,0.45);
}
@media (max-width: 767px) {
  ._2zVS2 {
    font-size: 12px;
  }
}
._3oz4n {
  opacity: 0;
  margin-left: auto;
  margin-right: 20px;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}
@media (max-width: 767px) {
  ._3oz4n {
    opacity: 1;
  }
}
._2W7hk {
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
._1mT3X {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #fff;
  min-width: 72px;
  padding: 16px;
  display: flex;
  flex-shrink: 0;
}
._1mT3X:hover {
  cursor: pointer;
}
._1mT3X:hover .E00l4 {
  position: static;
  visibility: visible;
}
._1mT3X:hover .YJeMS {
  background-color: #70ac5b;
  border-color: #70ac5b;
}
._1mT3X:hover ._3wxvJ {
  display: none;
}
._1L8am {
  position: relative;
  margin-right: 8px;
}
@media (max-width: 767px) {
  ._1L8am {
    margin-right: 0;
    z-index: 1;
  }
}
._2gfpU {
  width: 40px;
  height: 40px;
}
.cpK3J {
  background-color: #bbdcb0;
}
._3wxvJ {
  color: #cacaca;
  font-size: 14px;
  line-height: 20px;
}
.YJeMS {
  margin-left: auto;
  position: relative;
  border: 1px solid #cacaca;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.E00l4 {
  visibility: hidden;
  position: absolute;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._1rH61 {
  margin-left: 0;
  border-color: #70ac5b;
}
@media (max-width: 767px) {
  ._1rH61 {
    margin-left: -8px;
  }
}
._1rH61:hover {
  background-color: #70ac5b;
}
._2gxd9 {
  color: #70ac5b;
}
._1CQg0 {
  background-color: #cacaca;
}
