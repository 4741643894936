._2Ndtr {
  position: relative;
  width: 120px;
  height: 120px;
  border: 1px solid rgba(0,0,0,0.25);
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: #fff;
}
.sP_IA {
  width: 80px;
  height: 80px;
}
._2Ndtr:hover {
  background: #eff6ed;
}
._2Ndtr:hover .RaSww {
  opacity: 0;
  height: 0;
}
._1PEqK {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
._1PEqK:hover {
  background-color: inherit;
}
.eFCfI {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.RaSww {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: #fff;
}
