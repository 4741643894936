._3QzhA {
  width: 100%;
  height: 300px;
  display: flex;
  background: #131313;
  color: #fff;
}
@media (max-width: 767px) {
  ._3QzhA {
    width: 41%;
    min-width: 238px;
    height: 316px;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
  }
  ._3QzhA:not(:nth-child(-n+2)) {
    margin-top: 20px;
  }
}
@media (max-width: 575px) {
  ._3QzhA {
    width: 272px;
    height: 361px;
  }
  ._3QzhA:not(:first-child) {
    margin-top: 20px;
  }
}
._1_yYp {
  width: 333px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
@media (max-width: 767px) {
  ._1_yYp {
    width: 100%;
    height: 220px;
  }
}
@media (max-width: 575px) {
  ._1_yYp {
    height: 265px;
  }
}
._1rF8p {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform ease 0.3s;
}
._3QzhA:hover ._1rF8p {
  transform: scale(1.1);
}
@media (max-width: 767px) {
  ._1rF8p {
    min-width: 100%;
    background-position: top;
  }
}
._2W3OL {
  padding: 37px 40px 32px;
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  transition: background ease 0.3s;
}
._3QzhA:hover ._2W3OL {
  background: #363636;
}
@media (max-width: 991px) {
  ._2W3OL {
    height: auto;
    padding-top: 40px;
  }
}
@media (max-width: 767px) {
  ._2W3OL {
    padding: 16px 24px 24px;
  }
}
._3WUYN {
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
}
@media (max-width: 767px) {
  ._3WUYN {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
}
._1x1MM {
  margin-top: 20px;
  height: 100%;
  font-size: 15px;
  line-height: 20px;
  color: #cacaca;
}
@media (max-width: 767px) {
  ._1x1MM {
    display: none;
  }
}
._3hVI4 {
  margin-top: auto;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #808080;
}
@media (max-width: 767px) {
  ._3hVI4 {
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
  }
}
