._3Qv63 {
  margin-bottom: 6px;
  padding: 2px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  border-radius: 31px;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}
._3Qv63:not(:last-child) {
  margin-right: 6px;
}
._3HCvn {
  color: rgba(0,0,0,0.75);
}
._2gepY {
  color: rgba(255,255,255,0.3);
}
.GYzD5 {
  color: rgba(0,0,0,0.45);
  border-color: rgba(0,0,0,0.25);
}
._1qnNN {
  color: #000;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding-right: 3px;
}
._1qnNN:hover {
  background-color: rgba(0,0,0,0.1);
}
._1YXzv {
  display: flex;
  margin-left: 11px;
}
._3Egdc a {
  text-decoration: underline;
  color: #bbdcb0;
}
