.X5rYG {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
._3IlxN {
  cursor: pointer;
  width: 116px;
  height: 116px;
  margin-bottom: 12px;
  border: 1px solid #c4c4c4;
}
._2G3cy {
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
