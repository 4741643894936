._68D3A {
  display: flex;
  margin-bottom: 40px;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: left;
}
._2-pGq {
  flex-shrink: 0;
  width: 443px;
  height: 143px;
  margin-right: 7px;
}
._3y1tj {
  display: block;
  max-width: 100%;
  -webkit-filter: invert(100%);
          filter: invert(100%);
}
._3dJdD {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
@media (max-width: 971px) {
  ._3dJdD {
    margin-right: 0;
  }
}
._1SMUL {
  font-size: 76px;
}
._2cpQ- {
  margin-left: 5px;
  text-decoration: underline;
  color: #fff;
}
._1sveW {
  margin-top: 24px;
}
._1zEr9 {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding-right: 20px;
  padding-left: 20px;
  color: #fff;
  background-color: #000;
  font-size: 16px;
  line-height: 24px;
}
._1zEr9:last-child {
  margin-bottom: 0;
}
._1zEr9:first-child {
  border-top-left-radius: 4px;
}
._1zEr9:last-child {
  border-bottom-left-radius: 4px;
}
._2CRrr {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}
