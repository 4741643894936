._1egQQ {
  width: 100%;
  display: flex;
  justify-content: center;
}
.rNXLU {
  margin: 0 76px;
}
@media (max-width: 1365px) {
  .rNXLU {
    margin: 0 40px;
  }
}
@media (max-width: 767px) {
  .rNXLU {
    margin: 0 83px;
  }
}
@media (max-width: 575px) {
  .rNXLU {
    margin: 0 24px;
  }
}
._3DM78 {
  padding: 80px 0;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 421px - 2 * 64px);
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #000;
}
@media (max-width: 575px) {
  ._3DM78 {
    padding: 36px 0;
  }
}
.wh3Pq > * {
  width: 100%;
  display: flex;
  align-items: center;
}
._7hDTi {
  width: 100%;
  display: flex;
}
._5-JJk {
  padding-bottom: 0 !important;
  margin-bottom: 24px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
}
._2Z6hE :first-child {
  padding-bottom: initial;
}
