.M4iux {
  margin-top: -80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
}
.NOjIZ {
  margin-top: 54px;
  margin-bottom: 24px;
  width: 100%;
  font-size: 36px;
  line-height: 48px;
}
._2RwDd {
  margin-bottom: 24px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
}
