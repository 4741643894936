._1lez3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
._2pO1i {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  ._2pO1i {
    flex-wrap: wrap;
  }
}
._2pO1i ._XE1V {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
._2pO1i img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.ATBaM {
  justify-content: center;
}
._2DCBs > div:first-child {
  margin-right: 40px;
}
@media (max-width: 575px) {
  ._2DCBs > div:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
._2pO1i:last-child {
  margin-bottom: 0;
}
