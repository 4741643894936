._2qNEg {
  padding: 0 8px;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}
._3YRHh {
  background: #cfdbf9;
}
._2qNEg:hover {
  background: #f1f2f2;
}
._2ggyM {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
