._2Vo9t {
  min-width: 100%;
  min-height: calc(100vh - 128px);
  display: flex;
  flex-direction: column;
}
@media (max-width: 1238px) {
  ._2Vo9t {
    padding: 0 !important;
  }
}
