._3wuri {
  min-height: 72px;
  max-width: 1004px;
  width: 100%;
  margin: 0 24px;
  padding: 16px;
  display: flex;
  align-items: center;
  color: #000;
  background: #bbdcb0;
  border-radius: 8px;
}
._3ekJZ {
  width: 100%;
  display: flex;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  z-index: 2;
}
@media (max-width: 575px) {
  ._3ekJZ {
    position: static;
    top: 0;
    margin-top: 40px;
  }
}
._35gcK {
  margin-left: 16px;
  flex-shrink: 0;
}
._35gcK :not(:last-child) {
  margin-right: 4px;
}
._3PMIh {
  margin-right: 4px;
  flex-shrink: 0;
}
._1JK2T {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-right: auto;
}
._3S1tq {
  min-width: 40px;
}
._2LPtx > a {
  color: #000;
}
._1s5RZ {
  margin-top: 60px;
  position: -webkit-sticky;
  position: sticky;
  top: 152px;
}
._1tXrm {
  position: relative;
}
.vUuTc {
  position: absolute;
  right: -68px;
}
@media (max-width: 991px) {
  .vUuTc {
    display: none;
  }
}
._3ZrMY {
  margin: 60px 0;
  width: 100%;
  max-width: 800px;
}
._2OFlO {
  margin: 0 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 575px) {
  ._2OFlO {
    margin: 0 24px;
  }
}
._3czRU {
  font-size: 14px;
  line-height: 20px;
}
._3czRU > ul {
  list-style: disc;
  -webkit-padding-start: 24px;
          padding-inline-start: 24px;
}
._3czRU a {
  color: inherit;
  text-decoration: underline;
  color: #fff;
}
._3czRU > :not(:last-child) {
  margin-bottom: 24px;
}
@media (max-width: 575px) {
  ._3czRU > :not(:last-child) {
    margin-bottom: 16px;
  }
}
._2IBfp {
  color: rgba(255,255,255,0.5);
}
._3Yuhj {
  font-size: 22px;
  line-height: 32px;
}
._2-KAK {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #c95f80;
}
._1grRc {
  margin: 60px 0;
}
