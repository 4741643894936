._11IL8 {
  padding: 24px;
  border-radius: 8px;
  border: 1px solid rgba(0,0,0,0.1);
}
.WnjNC {
  box-shadow: none;
  background: #bbdcb0;
}
.ekdLY {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: #000;
}
._1_GuR {
  margin-top: 8px;
  color: rgba(0,0,0,0.5);
}
._3t9xX {
  margin-top: 16px;
  display: flex;
}
._2UHzS {
  margin-right: 23px;
  cursor: pointer;
}
._2UHzS:last-child {
  margin-right: 0px;
}
._1wyEO {
  cursor: pointer;
  background: #000;
  height: 48px;
  font-size: 14px;
  width: 48px;
  border-radius: 100%;
  color: #bbdcb0;
  display: flex;
  align-items: center;
  justify-content: center;
}
._1TEZD {
  background-color: #bbdcb0;
}
._2XORu {
  background-color: #cacaca;
}
