._1YEuk {
  cursor: pointer;
  border: 0;
  padding: 4px;
  border-radius: 4px;
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.5px;
}
._3cak4 {
  color: rgba(0,0,0,0.45);
}
._3cak4:not(._3cak4._2q3LQ):hover {
  color: #000;
}
._3cak4._2q3LQ {
  background-color: #000;
  color: #fff;
}
.NFGG6 {
  color: rgba(255,255,255,0.75);
}
.NFGG6:not(.NFGG6._2q3LQ):hover {
  color: #fff;
}
.NFGG6._2q3LQ {
  background-color: #bbdcb0;
  color: #0c0c0c;
}
._2wUPo {
  display: -ms-grid;
  display: grid;
  margin-bottom: 16px;
  padding: 4px;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 8px;
}
._25zkB {
  box-shadow: 0 0 0 1px rgba(255,255,255,0.3);
}
.CzHe_ {
  box-shadow: 0 0 0 1px rgba(0,0,0,0.3);
}
@media (min-width: 568px) {
  ._2wUPo {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
