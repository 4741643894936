.X0reX {
  width: 100%;
  position: relative;
}
._3jwm8 {
  width: 100%;
}
._2OVa_ {
  width: 100% !important;
  height: 600px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
._2i497 {
  margin: auto;
  padding-bottom: 20px;
  z-index: 2;
  width: 100%;
  position: absolute;
  top: auto;
  bottom: 0;
  display: flex;
  justify-content: center;
  font-size: 8px;
}
._3pyVF {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: rgba(255,255,255,0.4);
  cursor: pointer;
  margin: 0 3px 0.5em;
  transition: background-color 0.1s, color 0.1s;
  border-radius: 100%;
}
._2vqmR {
  background-color: #fff;
}
._14S3E {
  z-index: 2;
  position: absolute;
  width: 80px;
  height: 100%;
  display: flex;
  top: 0;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  color: rgba(255,255,255,0.5);
  transition: all 0.2s ease-in-out;
}
._14S3E:hover {
  background-color: rgba(0,0,0,0.1);
  color: #fff;
}
.cKJFI {
  left: 0;
}
._1kdC1 {
  right: 0;
}
._3_QxN {
  width: 22px;
  height: 22px;
}
