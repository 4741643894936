._1COwa {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: hidden;
  color: #30148a;
  background-color: #dbd6eb;
}
@media (max-width: 991px) {
  ._1COwa {
    padding: 60px 0;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
  }
}
@media (max-width: 575px) {
  ._1COwa {
    align-items: flex-start;
    padding: 40px 0;
  }
}
._19BsI {
  background-color: #facad9;
}
.DURso {
  background-color: #cfdbf9;
}
.YSGjJ {
  min-width: 456px;
  width: 456px;
  min-height: 450px;
  height: 100%;
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  overflow-y: auto;
  color: #000;
}
@media (max-width: 991px) {
  .YSGjJ {
    max-width: 456px;
    height: auto;
    flex-shrink: 0;
    margin-top: 60px;
    order: 1;
    border-radius: 8px;
  }
}
@media (max-width: 575px) {
  .YSGjJ {
    padding: 24px 20px;
    min-width: auto;
    max-width: 100%;
    width: 100%;
    margin-top: 0;
    border-radius: 0;
  }
}
._2n1e3 {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #000;
}
._2n1e3 > *:nth-child(2) {
  color: rgba(0,0,0,0.5);
}
@media (max-width: 991px) {
  ._2n1e3 {
    margin-bottom: 40px;
  }
}
._2f7CF {
  margin: 24px 0;
  width: 100%;
  display: flex;
  align-items: center;
}
.tpxGk {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
@media (max-width: 575px) {
  .tpxGk {
    flex-direction: column-reverse;
    align-items: center;
  }
  .tpxGk :nth-child(2) {
    margin-bottom: 8px;
  }
}
._16RJF {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 991px) {
  ._16RJF {
    height: auto;
    max-width: 456px;
    width: 100%;
    flex-shrink: 0;
  }
}
@media (max-width: 575px) {
  ._16RJF {
    padding: 0 20px 40px 20px;
  }
}
