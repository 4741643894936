._3GTu7 {
  width: 68px;
  height: 68px;
  border-radius: 8px;
  margin: 6px;
  cursor: pointer;
  background-size: cover;
  -webkit-filter: saturate(0%);
          filter: saturate(0%);
  display: inline-flex;
  background-position: center;
  transition: all 0.1s ease-in-out;
}
.LnyzB {
  box-shadow: inset 0 0 0 2px #000;
  -webkit-filter: saturate(100%);
          filter: saturate(100%);
}
._3m8fz:hover {
  background-size: auto 110%;
}
