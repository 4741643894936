._28kwx {
  padding: 40px;
  padding-bottom: 50px;
  color: rgba(0,0,0,0.5);
  background: url("/static/images/rfp/rose.svg") no-repeat right 0 top 25%;
}
@media (max-width: 575px) {
  ._28kwx {
    padding: 20px;
  }
}
._1TpKW {
  margin-bottom: 16px;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
}
._3Ht8j {
  font-size: 14px;
  line-height: 20px;
}
._2jY_s {
  margin: 26px 0;
}
._3fhGU {
  display: flex;
  align-items: center;
}
._3fhGU:not(:last-child) {
  margin-bottom: 20px;
}
._2t_PJ {
  width: 16px;
  height: 16px;
}
._3Ffwh {
  width: auto;
  height: auto;
}
._18upE {
  margin-right: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
._3m8KI {
  color: #000;
  text-transform: uppercase;
}
._2ADRU {
  font-weight: bold;
}
._2XjX7 {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #f1f2f2;
}
._2B3FG {
  margin-top: 24px;
}
.YhqAD {
  margin-bottom: 12px;
}
._72Llh {
  min-width: auto;
  height: auto;
  padding: 0;
  border: none;
  display: inline;
  color: #000;
  font-size: 14px;
  font-weight: normal;
  text-decoration: underline;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._72Llh:not(:last-child) {
  margin-right: 13px;
}
._72Llh:hover {
  color: rgba(0,0,0,0.5);
}
