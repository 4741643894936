.gYTrO {
  padding: 6px 8px 6px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;
  overflow: hidden;
  background: #000;
  color: #cfdbf9;
  cursor: text;
}
.gYTrO:hover {
  margin: -1px;
  border: 1px solid #9bb5f8;
}
.gYTrO:focus-within {
  margin: -2px;
  border: 2px solid #9bb5f8;
}
._12uP3 {
  margin: -1px !important;
  color: #bf3517 !important;
  border: 1px solid #bf3517 !important;
}
._3wTXU {
  margin-right: 6px;
  padding: 0;
  width: 100%;
  height: 20px;
  display: flex;
  border: 0;
  color: inherit !important;
  background: inherit;
}
._3wTXU:placeholder {
  color: inherit;
}
._1BwEp {
  border-radius: 6px;
  color: rgba(255,255,255,0.5);
  background: rgba(255,255,255,0.1);
}
._1BwEp:hover {
  color: #000;
  background: #9bb5f8;
}
._1BwEp[disabled] {
  cursor: not-allowed;
  color: rgba(255,255,255,0.5) !important;
  background: rgba(255,255,255,0.1) !important;
}
.M8IW4 {
  width: 100% !important;
  background: transparent !important;
}
.M8IW4:before {
  width: 18px !important;
  height: 18px !important;
  min-width: auto !important;
  min-height: auto !important;
  background-color: transparent !important;
}
