._3eQhm {
  box-sizing: border-box;
  position: absolute;
  top: 300px;
  width: 138px;
  height: 32px;
  padding: 0 20px;
  margin: 0;
  cursor: pointer;
  background: #9bb5f8;
  border-radius: 4px 4px 0px 0px;
  box-shadow: 0px 2px 12px rgba(0,0,0,0.06), 0px 2px 4px rgba(0,0,0,0.08);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transform: rotate(-90deg);
  transform-origin: bottom left;
  color: #fff;
  text-decoration: none;
  z-index: 9999;
}
._1gnM_ {
  position: fixed;
  top: calc(50% - 250px);
  right: 0;
  transition: width 300ms ease-out;
  width: 0;
  z-index: 99999;
}
._30jKG {
  width: 16px;
  position: relative;
  text-align: center;
  transform: rotate(90deg) scale(0.85);
  left: -8px;
}
._1TN0p {
  text-decoration: none;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
