._3d44e {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
._1Bd2x {
  margin-bottom: 16px;
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
}
._1wnep {
  max-width: 256px;
  width: 100%;
}
.jm_32 {
  margin-top: 16px;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0,0,0,0.65);
}
.jm_32 a {
  color: #000;
  cursor: pointer;
}
._10cde {
  display: flex;
  justify-content: center;
  align-items: center;
}
._1bwCK {
  width: 352px;
  min-height: 508px;
  border-radius: 8px;
}
@media (max-width: 567px) {
  ._1bwCK {
    margin: 0;
    width: 320px;
    max-width: 352px;
  }
}
._2iNjB {
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
  color: #000;
}
._3lu1c {
  color: rgba(0,0,0,0.5);
  margin-top: 8px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}
._3hkyi {
  margin-top: 24px;
  width: 100%;
  display: flex;
}
._1lThy {
  margin-top: 20px;
  margin-bottom: 60px;
  width: 157px;
  height: 116px;
}
._1cq5G {
  margin-top: 32px;
  width: 100%;
}
._3hkyi {
  margin-top: 16px;
  justify-content: space-between;
}
._3n4R- {
  flex-basis: 34%;
}
._3ntX9 {
  flex-basis: 61%;
}
._3_V53 {
  width: 320px;
  height: 216px;
}
@media (max-width: 567px) {
  ._3_V53 {
    margin: 0;
    max-width: 320px;
  }
}
._1z4OT {
  justify-content: center;
}
._1LIyP {
  flex-basis: 66.6%;
}
