._2i6qj {
  position: relative;
}
@media (max-width: 767px) {
  ._2i6qj {
    margin-right: 0;
  }
}
._1qOTQ {
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
