._1PQwp {
  color: #000;
}
.YR8JP {
  width: 560px;
  padding-bottom: 0px;
}
@media (max-width: 575px) {
  .YR8JP {
    width: 100%;
  }
}
.LkKXd {
  width: 100%;
  display: flex;
  flex-direction: column;
}
._2yjNs {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
}
._3VNj1 {
  padding: 0 40px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(0,0,0,0.5);
}
@media (max-width: 991px) {
  ._3VNj1 {
    padding: 0 24px;
  }
}
._3Hdtp {
  width: 100%;
  margin-top: 40px;
  flex: 1;
}
._3y12H {
  display: flex;
  flex-direction: column;
}
._1TzeB {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
._1Wp8N {
  margin-top: 4px;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
  color: rgba(0,0,0,0.5);
}
.iGkNH {
  margin-top: 40px;
  height: 96px;
  width: 100%;
  border-top: 1px solid #f1f2f2;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
._3FdmM {
  font-size: 16px;
  height: 48px;
}
