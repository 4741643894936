._1OJA5 {
  width: 100%;
  margin-top: 96px;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -1px;
  color: #000;
}
@media (max-width: 767px) {
  ._1OJA5 {
    margin-top: 80px;
  }
}
@media (max-width: 575px) {
  ._1OJA5 {
    font-size: 22px;
    line-height: 32px;
    margin: 40px 0;
  }
}
._3j_rj {
  display: flex;
  align-items: center;
}
.YYWZI {
  width: 180px;
  height: 180px;
  margin-right: 24px;
  border-radius: 20px;
  display: flex;
  align-items: center;
}
.YYWZI > img {
  border-radius: 20px;
}
._1oR2T {
  width: 40px;
  height: 40px;
  margin-top: 0.5rem;
  float: left;
  border-radius: 50%;
  border: 2px solid rgba(0,0,0,0.32);
}
@media (max-width: 575px) {
  ._1oR2T {
    margin: 0;
    width: 32px;
    height: 32px;
  }
}
._3lCUy {
  width: 100%;
  height: 100%;
}
._2q6KS {
  display: inline;
  font-weight: 700;
}
._2_SmV {
  display: inline-block;
  height: 36px;
  margin-bottom: 12px;
  padding: 0 16px;
  border: 1px solid #000;
  border-radius: 60px;
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  cursor: pointer;
  color: #fff;
  background-color: #000;
}
._3uvXO {
  display: inline-block;
}
._3oeev {
  cursor: pointer;
  margin-top: 20px;
  padding: 2px;
  width: 88px;
  min-width: auto;
  height: auto;
  background-color: transparent;
  border: 2px solid rgba(0,0,0,0.25);
  border-radius: 31px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: rgba(0,0,0,0.45);
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  letter-spacing: 1px;
}
.V36KV {
  margin-left: 3px;
  margin-right: 4px;
  opacity: 0.45;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._3oeev:hover {
  border-color: #000;
  color: #000;
}
._3oeev:hover > .V36KV {
  opacity: 1;
}
