._2DHGb > a {
  color: #000;
}
._1_e0a {
  width: 116px;
  height: 116px;
}
._3Vnp4 {
  width: 100%;
}
