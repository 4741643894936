._12rlm {
  border-radius: 8px;
}
._1M_gy {
  margin-top: 24px;
  display: flex;
}
@media (max-width: 767px) {
  ._1M_gy {
    flex-direction: column;
    align-items: flex-start;
  }
}
._vCLT {
  width: 100%;
  margin-left: 4px;
}
@media (max-width: 767px) {
  ._vCLT {
    margin-top: 6px;
    margin-left: 0;
  }
}
._1HY9V {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 4px;
  max-width: 428px;
}
@media (max-width: 1365px) {
  ._1HY9V {
    grid-template-columns: repeat(5, 1fr);
    max-width: 356px;
  }
}
@media (max-width: 991px) {
  ._1HY9V {
    max-width: 326px;
  }
}
._2louj {
  margin: 0;
}
@media (max-width: 991px) {
  ._2louj {
    width: 56px;
    height: 56px;
  }
}
@media (max-width: 575px) {
  ._2louj {
    width: 50px;
    height: 50px;
  }
}
._2e0Dz {
  width: 68px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #000;
  border-radius: 8px;
  cursor: pointer;
}
._2e0Dz:focus {
  transform: scale(0.9);
}
@media (max-width: 991px) {
  ._2e0Dz {
    width: 56px;
    height: 56px;
  }
}
@media (max-width: 575px) {
  ._2e0Dz {
    width: 50px;
    height: 50px;
  }
}
.KxVNh {
  width: 24px;
  height: 24px;
}
