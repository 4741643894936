._3J6dy {
  padding: 24px 0;
  margin-bottom: 24px;
  border-bottom: 1px solid #f1f2f2;
  flex-direction: row;
  align-items: center;
}
._30lx5 {
  color: #000;
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
}
._3Y_r6 {
  flex-shrink: 0;
}
._3Y_r6:before {
  border-radius: 8px;
  width: 100% !important;
  height: 100% !important;
}
._1nIVq {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}
._1K0Hi {
  margin-left: 20px;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgba(0,0,0,0.5);
}
