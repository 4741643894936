._3PUhH {
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
._3ZYSx {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(0,0,0,0.1);
  transition: background 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._3_JWx {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
._27bt4 {
  cursor: help;
}
._3ZYSx:not(:last-child) {
  margin-right: 16px;
}
._1hFaQ {
  background: #000;
}
