.q-ZwK {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  color: #000;
  border-radius: 8px;
  overflow: hidden;
}
._2Li55 {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  opacity: 0.5;
  color: inherit;
}
._3Vwd2 {
  color: #bf3517;
}
._1LFxo {
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border: 1px solid rgba(0,0,0,0.25);
  font-size: 16px;
  line-height: 24px;
  cursor: text;
  background: inherit;
  color: inherit;
  border-radius: 8px;
}
.q-ZwK:hover ._1LFxo {
  border: 1px solid rgba(0,0,0,0.45);
}
._14bC1 {
  border: 1px solid #000;
}
.q-ZwK:hover ._14bC1 {
  border: 1px solid #000;
}
.nMMkS {
  background: rgba(0,0,0,0.1);
  border: 1px solid rgba(0,0,0,0.1);
  cursor: default;
}
__container--disabled:hover .nMMkS {
  border: 1px solid rgba(0,0,0,0.1);
}
.q-ZwK:hover .nMMkS {
  border: 1px solid rgba(0,0,0,0.1);
}
._1cSlf {
  border-color: #bf3517;
}
._3bIzP {
  margin: 12px 0 12px 16px;
  width: 100%;
  padding: 0;
  border: 0;
  background: transparent;
  color: inherit;
}
._3bIzP[disabled]::-webkit-input-placeholder {
  opacity: 0.25;
}
._3bIzP[disabled]:-ms-input-placeholder {
  opacity: 0.25;
}
._3bIzP[disabled]::-ms-input-placeholder {
  opacity: 0.25;
}
._3bIzP[disabled]::placeholder {
  opacity: 0.25;
}
.tjYun {
  padding: 14px 12px;
  margin-right: -4px;
  width: 68px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0,0,0,0.25);
  border-right: 1px solid rgba(0,0,0,0.25);
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
._3PRbP {
  margin-left: 4px;
  width: 20px;
  height: 20px;
}
.q-ZwK:placeholder {
  font-size: 16px;
  line-height: 24px;
  opacity: 0.5;
  color: inherit;
}
