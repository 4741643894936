._3EYgT {
  margin-top: 120px;
  width: 100%;
  color: #fff;
}
._3fLzX {
  margin-bottom: 60px;
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  color: #fff;
}
@media (max-width: 767px) {
  ._3fLzX {
    margin-bottom: 40px;
    font-size: 32px;
  }
}
@media (max-width: 575px) {
  ._3fLzX {
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 32px;
  }
}
._1mqgP {
  color: #fff;
  text-decoration: underline;
}
.s-KF9 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 20px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .s-KF9 {
    border-radius: 0;
    overflow: auto;
  }
}
@media (max-width: 575px) {
  .s-KF9 {
    justify-content: center;
  }
}
