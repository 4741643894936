._2p35Q {
  padding: 40px;
  color: rgba(0,0,0,0.5);
}
@media (max-width: 575px) {
  ._2p35Q {
    padding: 20px;
  }
}
._1XTA6:not(:last-child) {
  margin-bottom: 24px;
}
.vxVc4 > *:not(:last-child) {
  margin-bottom: 24px;
}
.DPDYU {
  width: 100%;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 8px;
}
._2NufF {
  border: none;
  height: auto;
  display: inline;
  color: #000;
  text-decoration: underline;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._2NufF:hover:not(._GHil) {
  color: rgba(0,0,0,0.5);
}
