.dn4vK {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 575px) {
  .dn4vK {
    height: 128px;
    flex-direction: column;
    justify-content: center;
  }
}
.TQ7ve {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  z-index: 3;
}
._2NlIX {
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.SwjfK {
  border-bottom: 1px solid rgba(255,255,255,0.1);
}
._2J0Zj {
  flex: 1;
  height: 100%;
  display: flex;
}
.a2F1A {
  position: relative;
  display: flex;
  align-items: center;
}
.a2F1A:not(:last-child) {
  margin-right: 24px;
}
.a2F1A > a {
  color: inherit;
}
._3uwaW {
  font-weight: bold;
}
._19d41 {
  position: absolute;
  bottom: 0;
  height: 3px;
  width: 100%;
  background-color: currentColor;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
._1_aua {
  cursor: not-allowed;
  opacity: 0.4;
}
._1_aua > a {
  cursor: not-allowed;
}
@media (max-width: 575px) {
  .bZ2BR {
    margin: 12px 0;
  }
}
._29HM4 {
  margin-right: 16px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
._3Agk4 {
  color: #70ac5b;
}
._1Y-Or {
  color: #ebf590;
}
._3KFvI {
  color: #c95f80;
}
._2fblP {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  color: #fff;
}
._2Hspb {
  margin-right: 16px;
}
._2Hspb > a {
  color: inherit;
}
@media (max-width: 575px) {
  ._2Hspb {
    margin-right: 0;
  }
  ._2Hspb:not(:last-child) {
    margin-right: 4px;
  }
}
