._1gXcK {
  margin-right: 6px;
  padding: 0;
  width: 100%;
  height: 20px;
  display: flex;
  border: 0;
  color: inherit;
  background: inherit;
}
._1gXcK::-webkit-input-placeholder {
  color: #cfdbf9;
}
._1gXcK:-ms-input-placeholder {
  color: #cfdbf9;
}
._1gXcK::-ms-input-placeholder {
  color: #cfdbf9;
}
._1gXcK::placeholder {
  color: #cfdbf9;
}
.mocCB {
  margin-bottom: 16px;
  max-width: 375px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255,255,255,0.5);
}
._3lWpn {
  padding: 6px 8px 6px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  border-radius: 6px;
  overflow: hidden;
  background: #000;
  color: #cfdbf9;
  cursor: text;
}
._3lWpn:hover {
  box-shadow: 0 0 0 1px #9bb5f8;
}
._3lWpn:focus-within {
  box-shadow: 0 0 0 2px #9bb5f8;
}
._3CZQX,
._3CZQX:hover {
  color: #bf3517;
  box-shadow: 0 0 0 1px #bf3517;
}
._3CZQX:focus-within,
._3CZQX:hover:focus-within {
  box-shadow: 0 0 0 2px #bf3517;
}
.-diRz {
  border-radius: 6px;
  color: rgba(255,255,255,0.5);
  background: rgba(255,255,255,0.1);
  font-weight: normal;
  letter-spacing: 0.5px;
}
.-diRz:hover,
._2jhWb {
  color: #000;
  background: #cfdbf9;
}
.-diRz[disabled] {
  cursor: not-allowed;
  color: rgba(255,255,255,0.5) !important;
  background: rgba(255,255,255,0.1) !important;
}
