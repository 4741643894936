._2tErP {
  width: 100%;
  display: flex;
  justify-content: center;
}
._3IBK5 {
  position: absolute;
  z-index: 2;
  display: flex;
  padding-top: 12px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
._2tErP:hover ._3IBK5 {
  opacity: 1;
}
._3-hhA {
  margin-left: 8px;
  background-color: rgba(0,0,0,0.25);
  transition: all 100ms ease-in-out;
}
._3-hhA:hover {
  background-color: #000;
}
._3-hhA:first-child {
  margin-left: 0;
}
.odhVY {
  transform: rotate(-90deg);
}
.odhVY:active {
  transform: scale(0.9) rotate(-90deg);
}
