.ZQGtj {
  margin-bottom: 24px;
  display: flex;
}
._2ShSv {
  height: 40px;
  flex: 1;
  border: none;
  border-radius: 37px;
  font-size: 10px;
  line-height: 16px;
  color: rgba(0,0,0,0.5);
}
._2ShSv:not(:last-child) {
  margin-right: 4px;
}
._2ShSv:not([disabled]):hover,
._2ShSv:not([disabled]):focus {
  background: rgba(0,0,0,0.05);
}
._2ShSv:not([disabled]):active {
  box-shadow: inset 0px 0px 0 1px rgba(0,0,0,0.1);
  background: rgba(0,0,0,0.1);
}
._1gmRh {
  box-shadow: inset 0px 0px 0 1px rgba(0,0,0,0.1);
  color: #000;
}
._1gmRh:not([disabled]):hover {
  color: inherit;
}
