.N6sJ0 {
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
._2LKWg {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  color: rgba(255,255,255,0.5);
  font-size: 14px;
}
._3tMRx {
  justify-content: center;
  line-height: 22px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
._3tMRx:active {
  transform: scale(0.9);
}
._3_rzd {
  margin-bottom: 0;
  margin-top: -2px;
  margin-right: 8px;
  width: 22px;
  color: #fff;
  height: 22px;
}
