._1clpc {
  display: flex;
}
._1clpc:not(:last-child) {
  margin-bottom: 4px;
}
.ksR1U {
  position: relative;
  border-radius: 8px;
}
.ksR1U:not(:last-child) {
  margin-right: 4px;
}
.ksR1U:hover ._1JybT {
  opacity: 1;
}
.ksR1U:hover ._1jeWe {
  visibility: hidden;
}
._3Y__R {
  -webkit-filter: saturate(0%);
          filter: saturate(0%);
  border-radius: 8px;
  height: 168px;
  width: 168px;
}
._35dyH {
  -webkit-filter: none;
          filter: none;
}
._2dVvn {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  height: 168px;
  width: 168px;
  display: flex;
  justify-content: center;
  align-items: center;
}
._1jeWe {
  height: 56px;
  width: 56px;
  background: rgba(0,0,0,0.75);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._21ScP {
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
._21ScP svg {
  height: 24px;
  width: 24px;
  transform: rotate(45deg);
  transition: transform 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._3UPU5 svg {
  transform: none;
}
._1JybT {
  opacity: 0;
  background: rgba(0,0,0,0.5);
  transition: opacity 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._1JybT:hover {
  background-color: rgba(0,0,0,0.5);
}
