._3MOoH {
  width: 100%;
  max-width: 852px;
  color: #fff;
}
@media (max-width: 1365px) {
  ._3MOoH {
    max-width: 756px;
  }
}
@media (max-width: 991px) {
  ._3MOoH {
    max-width: none;
    padding: 0 40px;
  }
}
@media (max-width: 575px) {
  ._3MOoH {
    padding: 0 8px;
  }
}
._1dVDC {
  margin-bottom: 12px;
  padding: 24px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  border: 0;
  border-radius: 8px;
  background: rgba(255,255,255,0.1);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._1dVDC[disabled] {
  cursor: initial;
  color: rgba(255,255,255,0.5);
  border: initial;
  background-color: rgba(255,255,255,0.1);
}
._1dVDC:not([disabled]):hover {
  color: #fff;
  background: rgba(255,255,255,0.3);
}
@media (max-width: 575px) {
  ._1dVDC {
    margin-bottom: 8px;
  }
}
._2EpHI {
  margin-right: 10px;
  width: 22px;
  height: 22px;
  fill: #fff;
}
._10moq {
  margin-bottom: 40px;
}
._1qXpC {
  font-size: 36px;
  line-height: 48px;
}
@media (max-width: 767px) {
  ._1qXpC {
    margin-bottom: 40px;
    font-size: 26px;
    line-height: 32px;
  }
}
@media (max-width: 575px) {
  ._1qXpC {
    padding-left: 16px;
  }
}
.x_wj_ {
  margin-top: 16px;
  max-width: 625px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255,255,255,0.75);
}
._3EF8Q {
  margin-top: 40px;
}
