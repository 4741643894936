.R2ehj {
  margin-bottom: 32px;
  height: 56px;
  padding: 4px;
  display: flex;
  align-items: center;
  background: #f1f2f2;
  border-radius: 8px;
}
.R2ehj > * {
  flex: 1;
}
._3x-4Q {
  height: 48px;
  padding: 12px 16px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: start;
  background: none;
  color: rgba(0,0,0,0.5);
}
._3x-4Q:not(:last-child) {
  margin-right: 4px;
}
._3x-4Q[disabled] {
  color: rgba(0,0,0,0.5);
}
._1afLt {
  background: #fff;
  color: #000;
}
._1afLt[disabled] {
  color: #000;
  background: rgba(255,255,255,0.5);
}
