.S1Wex {
  width: 100%;
  height: 100%;
}
._2PdZB {
  display: flex;
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.LWsaC {
  color: #c95f80;
}
._2t_pt {
  color: #227422;
}
._2JFUT {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0,0,0,0.5);
}
