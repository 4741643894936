.skD3j {
  display: inline-block;
}
.xhRu3 {
  animation-duration: 0.35s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
}
@media (hover: hover) {
  [class*='MovingArrowWrapper']:hover .xhRu3 {
    animation-name: _1buW2;
  }
  [class*='MovingArrowWrapper']:active .xhRu3 {
    animation-duration: 0.7s;
  }
}
@keyframes _1buW2 {
  0% {
    opacity: 0;
    transform: translateX(-2px);
  }
  50% {
    opacity: 1;
    transform: translateX(6px);
  }
  100% {
    opacity: 0;
    transform: translateX(14px);
  }
}
