.u0Uzo {
  position: relative;
  top: 16px;
  left: 0px;
  padding: 24px 24px 8px;
  z-index: 8;
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 8px;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  background: #141414;
  box-shadow: 0 4px 7px rgba(0,0,0,0.17), 0 1px 1px rgba(0,0,0,0.132);
  color: #fff;
}
.u0Uzo:after {
  content: '';
  position: absolute;
  align-self: center;
  width: 18px;
  height: 18px;
  right: 47px;
  top: -8px;
  border-radius: 2px;
  transform: rotate(45deg);
  background-color: #141414;
}
._2k5LQ {
  display: flex;
  align-items: center;
}
